import dexie from 'dexie';

export const db = new dexie('AgreementCopy');
db.version(5).stores({
  parts: '++id, name, value, date',
  services: '++id, name, value, date',
  periods: '++id, name, value, date',
  prices: '++id, name, value, date',
  royaltyServices: '++id, name, value, date',
  royaltyPeriods: '++id, name, value, date',
  royaltyPrices: '++id, name, value, date',
  conditionLoaders: '++id, name, value, date',
  });
