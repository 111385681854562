import { createSlice } from '@reduxjs/toolkit';
import { AgreementStages } from '../../../entities/AgreementStages';

const initialState = {
  accordionView: 'on',
  expandedAccordionList: true,
  expandedAccordionMain: false,
  expandedAccordionPart: false,
  expandedAccordionService: false,
  expandedAccordionApproval: false,
  stageName: AgreementStages.CAlist,
};

export const navigationSlice = createSlice({
  name: 'CAnavigation',
  initialState,
  reducers: {
    setAccordionViewOn: (state) => {
      state.accordionView = 'on';
    },
    setAccordionViewOff: (state) => {
      state.accordionView = 'off';
    },
    setStageName: (state, action) => {
      state.stageName = action.payload;
      switch (action.payload) {
        case AgreementStages.CAlist:
          state.expandedAccordionList = true;
          state.expandedAccordionMain = false;
          state.expandedAccordionPart = false;
          state.expandedAccordionService = false;
          state.expandedAccordionApproval = false;
          break;
        case AgreementStages.CAmain:
          state.expandedAccordionList = false;
          state.expandedAccordionMain = true;
          state.expandedAccordionPart = true;
          state.expandedAccordionService = false;
          state.expandedAccordionApproval = false;
          break;
        case AgreementStages.CApart:
          state.expandedAccordionList = false;
          state.expandedAccordionMain = false;
          state.expandedAccordionPart = true;
          state.expandedAccordionService = true;
          state.expandedAccordionApproval = false;
          break;
        case AgreementStages.CAservice:
          state.expandedAccordionList = false;
          state.expandedAccordionMain = false;
          state.expandedAccordionPart = false;
          state.expandedAccordionService = true;
          state.expandedAccordionApproval = false;
          break;
        case AgreementStages.CAapproval:
          state.expandedAccordionList = false;
          state.expandedAccordionMain = false;
          state.expandedAccordionPart = false;
          state.expandedAccordionService = false;
          state.expandedAccordionApproval = true;
          break;
        default:
          break;
      }
    },
    switchAccordionExpandedState: (state, action) => {
      switch (action.payload?.index) {
        case 0:
          state.expandedAccordionList = action.payload?.value;
          break;
        case 1:
          state.expandedAccordionMain = action.payload?.value;
          break;
        case 2:
          state.expandedAccordionPart = action.payload?.value;
          break;
        case 3:
          state.expandedAccordionService = action.payload?.value;
          break;
        case 4:
          state.expandedAccordionApproval= action.payload?.value;
          break;
        default:
      }
      // if(action.payload?.index >=0 && action.payload?.index <=3) {
      //       state.expandedAccordions[action.payload.index] = action.payload.value ;

      //   }
    },
  },
});

export const {
  setAccordionViewOn,
  setAccordionViewOff,
  setStageName,
  switchAccordionExpandedState,
} = navigationSlice.actions;
export default navigationSlice.reducer;
