import { createSlice } from '@reduxjs/toolkit';
import { AgreementStages } from '../../../entities/AgreementStages';

const initialState = {
  accordionView: 'on',
  expandedAccordionList: true,
  expandedAccordionDetail: false,
  expandedAccordionApproval: false,
  stageName: AgreementStages.ARlist,
};

export const ARnavigationSlice = createSlice({
  name: 'ARnavigation',
  initialState,
  reducers: {
    setAccordionViewOn: (state) => {
      state.accordionView = 'on';
    },
    setAccordionViewOff: (state) => {
      state.accordionView = 'off';
    },
    setStageName: (state, action) => {
      state.stageName = action.payload;
      switch (action.payload) {
        case AgreementStages.ARlist:
          state.expandedAccordionList = true;
          state.expandedAccordionDetail = false;
          state.expandedAccordionApproval = false;
          break;
        case AgreementStages.ARDetail:
          state.expandedAccordionList = false;
          state.expandedAccordionDetail = true;
          state.expandedAccordionApproval = false;
          break;
        // case AgreementStages.ARapproval:
        //   state.expandedAccordionList = false;
        //   state.expandedAccordionDetail = false;
        //   state.expandedAccordionApproval = true;
        //   break;
        default:
          break;
      }
    },
    switchAccordionExpandedState: (state, action) => {
      switch (action.payload?.index) {
        case 0:
          state.expandedAccordionList = action.payload?.value;
          break;
        case 1:
          state.expandedAccordionDetail = action.payload?.value;
          break;
        // case 2:
        //   state.expandedAccordionApproval = action.payload?.value;
        //   break;
        default:
          break;
      }
    },
  },
});

export const {
  setAccordionViewOn,
  setAccordionViewOff,
  setStageName,
  switchAccordionExpandedState,
} = ARnavigationSlice.actions;
export default ARnavigationSlice.reducer;