import React, { useEffect, useState, useMemo } from 'react';
import PageContainer from '../../components/PageContainer';
import useLocale from '../../hooks/useLocale'
import { useDispatch, useSelector } from 'react-redux';
import useSettings from '../../hooks/useSettings';

import { Grid, Typography} from "@mui/material";
import usePermissions from "../../hooks/usePermissions";
import FormulaTable from "./FormulaTable";


export default function Formula() {
    //const { themeSize } = useSettings();
    const dispatch = useDispatch();
    const {themeStretch} = useSettings();
    const { hasPermission } = usePermissions({ pageName: 'FormulaTable' });


    {/* Translate */ }
    const { t } = useLocale();
    const translate = (value) => {
        let _t = `ConcessionFee.FormulaTable.index.${value}`;
        return t(_t);
    };

    useEffect(()=>{
    },[]);

    return (
        <>
            <PageContainer title={translate('Formula Table')}>
                <Grid container alignItems="center">
                    <Grid item xs={12}>
                        <Typography sx={{ ml: 2 }} variant="h4">
                            {translate('Formula Table')}
                        </Typography>
                    </Grid>
                    {/*<Grid item xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>*/}
                    {/*    {hasPermission('add')&&(<CRForm action="Add"></CRForm>)}*/}
                    {/*</Grid>*/}
                </Grid>
                <FormulaTable></FormulaTable>
            </PageContainer>
        </>
    );
}