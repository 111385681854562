
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { HEADER, NAVBAR } from '../../../config';
import PersistentDrawer from '../../NavBar/PersistentDrawer';
import RoleBasedGuard from '../../../routes/guards/RoleBasedGuard';
import { useSelector } from 'react-redux';
import MenuGenerator from '../../NavBar/sections/MenuGenerator';


const MainStyle = styled(
  'main',
  {}
)(({ theme }) => ({
  flexGrow: 1,
  position: 'relative',
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: 0,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

export default function DashboardLayout() {
  const loading = useSelector((state) => state.menu.loading);
  const menu = useSelector((state) => state.menu.menu);
  const isDrawerOpen = localStorage.getItem('drawerOpen') ?? true;

  return (
    <Box
      sx={{ display: { lg: 'flex' }, minHeight: { lg: 1 }, paddingBottom: 0 }}
    >
     {!loading && menu && menu?.length === 0 &&  <MenuGenerator />}
      {!loading && <PersistentDrawer isDrawerOpen={isDrawerOpen} />}
      <MainStyle>
        <RoleBasedGuard>
          <Outlet />
        </RoleBasedGuard>
      </MainStyle>
    </Box>
  );
}
