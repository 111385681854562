import PropTypes from 'prop-types';
import { m } from 'framer-motion';
import { forwardRef } from 'react';
import { Box, IconButton as Btn } from '@mui/material';

const varSmall = { hover: { scale: 1.1 }, tap: { scale: 0.95 } };

const varMedium = { hover: { scale: 1.09 }, tap: { scale: 0.97 } };

const varLarge = { hover: { scale: 1.08 }, tap: { scale: 0.99 } };

function AnimateWrapper({ size, children }) {
  const isSmall = size === 'small';
  const isLarge = size === 'large';

  return (
    <Box
      component={m.div}
      whileTap="tap"
      whileHover="hover"
      variants={(isSmall && varSmall) || (isLarge && varLarge) || varMedium}
      sx={{ display: 'inline-flex' }}
    >
      {children}
    </Box>
  );
}

const IconButton = forwardRef(({ children, size = 'medium', ...other }, ref) => (
  <AnimateWrapper size={size}>
    <Btn size={size} ref={ref} {...other}>
      {children}
    </Btn>
  </AnimateWrapper>
));

IconButton.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['inherit', 'default', 'primary', 'secondary', 'info', 'success', 'warning', 'error']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

AnimateWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default IconButton;
