import { createSlice } from '@reduxjs/toolkit';
import { AgreementStages } from '../../../entities/AgreementStages';

const initialState = {
  accordionView: 'on',
  expandedAccordionList: true,
  expandedAccordionService: false,
  expandedAccordionApproval: false,
  stageName: AgreementStages.RAlist,
};

export const navigationSlice = createSlice({
  name: 'RAnavigation',
  initialState,
  reducers: {
    setAccordionViewOn: (state) => {
      state.accordionView = 'on';
    },
    setAccordionViewOff: (state) => {
      state.accordionView = 'off';
    },
    setStageName: (state, action) => {
      state.stageName = action.payload;
      switch (action.payload) {
        case AgreementStages.RAlist:
          state.expandedAccordionList = true;
          state.expandedAccordionService = false;
          state.expandedAccordionApproval = false;
          break;
        case AgreementStages.RAservice:
          state.expandedAccordionList = false;
          state.expandedAccordionService = true;
          state.expandedAccordionApproval = false;
          break;
        case AgreementStages.RAapproval:
          state.expandedAccordionList = false;
          state.expandedAccordionService = false;
          state.expandedAccordionApproval = true;
          break;
        default:
          break;
      }
    },
    switchAccordionExpandedState: (state, action) => {
      switch (action.payload?.index) {
        case 0:
          state.expandedAccordionList = action.payload?.value;
          break;
        case 1:
          state.expandedAccordionService = action.payload?.value;
          break;
        case 2:
          state.expandedAccordionApproval = action.payload?.value;
          break;
        default:
      }
    },
  },
});

export const {
  setAccordionViewOn,
  setAccordionViewOff,
  setStageName,
  switchAccordionExpandedState,
} = navigationSlice.actions;
export default navigationSlice.reducer;
