import { useSelector } from "react-redux";
import MathExpressionBox from "../../../components/ExpressionBox/MathExpressionBox";

// const priceSuggestions = [
//     'Always',
//     'Legs',
//     'Legs/2',
//     'ExtPrice',
//     'ExternalPrice',
//     'TimeAsHourUp',
//     'TimeAsHourDown',
//     'ThirdPartyPrice',
//     'PaxIn',
//     'PaxOut',
//     'TransferPax',
//     'FreeUpToY(,)',
//     'XUpToY(,,,)',
//     'XForY(,,,)',
//     'ServiceQuantity',
//     'TotalServiceQuantity',
//     'RoundedUp()',
//     'ceil()',
//     'TotalCargo',
//     'TotalCargoInTon',
//     'CargoIn',
//     'CargoOut',
//     'TotalMail',
//     'TotalMailInTon',
//     'MailIn',
//     'MailOut',
//     'BaggageIn',
//     'BaggageOut',
//     'TotalBaggage',
//     'TotalBaggageInTon',
//     'TimeAs30MinUp',
//     'TimeAs15MinUp',
//     'SrvTimeHr',
//     'PriceSequence(,,,,,,)',
//     'SeatCapacity',
// ];
const suggestions = [
    'Price',
    'MGA',
    'AERA',
    'ATF',
    'AOCRIC',
    'AOCRFC',
    'CFER',
    'CFIR',
    'CF on GHA',
    'FF'
]
export default function PriceFunctionBox(props) {
    return <MathExpressionBox suggestions={suggestions} {...props} />;
};
