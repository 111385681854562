import { createSlice } from '@reduxjs/toolkit';
import secureLocalStorage from "react-secure-storage";
const UserInfo = JSON.parse(secureLocalStorage.getItem('user'))

const initialState = {
    globalLoading:{open:false, info:'Processing...'}
}
export const MainUISlice = createSlice({
    name: 'MainUI',
    initialState,
    reducers:{
        setGlobalLoading:(state,action)=>{
            state.globalLoading = action.payload;
        }
    }
});

export const {
    setGlobalLoading
} = MainUISlice.actions;
export default MainUISlice.reducer;