export const FormStates = {
  unChanged: 'unChanged',
  saving: 'saving',
  modified: 'modified',
};

export const SaveStates = { 
    idle: 'idle', 
    success: 'success', 
    error: 'error' 
};
