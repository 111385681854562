import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    accordionView: 'on',
    Filter: true,
    GroupPreview: false,
    DisplayingTheCreatedInvoice: false,
    InvoiceStatus: false,
    Step: '1',
    FormData: {},
    SelectItems: {},
    FormDate: null,
    FilterSearchForm: {
        Airport: {
            select: []
        },
        Customers: {
            select: []
        },
        Airline: {
            select: ""
        },
        Registration: {
            select: []
        },
    },
    CreateInvoiceParams: {},
    Permissions: {}
};

export const InvoiceGatFilterNavigationSlice = createSlice({
    name: 'InvoiceGatFilterNavigation',
    initialState,
    reducers: {
        setStep: (state, action) => {
            state.Step = action.payload;
        },
        setAccordionViewOn: (state) => {
            state.accordionView = 'on';
        },
        setAccordionViewOff: (state) => {
            state.accordionView = 'off';
        },
        setStageName: (state, action) => {
            state.Filter = true;
            state.GroupPreview = false;
            state.DisplayingTheCreatedInvoice = false;
            state.InvoiceStatus = false;
        },
        switchAccordionExpandedState: (state, action) => {
            switch (action.payload?.index) {
                case 0:
                    state.Filter = action.payload?.value;
                    break;
                case 1:
                    state.GroupPreview = action.payload?.value;
                    break;
                case 2:
                    state.DisplayingTheCreatedInvoice = action.payload?.value;
                    break;
                case 3:
                    state.InvoiceStatus = action.payload?.value;
                    break;
                default:
            }
        },
        setFormData: (state, action) => {
            state.FormData = action.payload;
        },
        setSelectItems: (state, action) => {
            state.SelectItems = action.payload;
        },
        setFormDateRedux: (state, action) => {
            state.FormDate = action.payload
        },
        setInvoiceStatus: (state, action) => {
            state.FormData.Displaying.Status = action.payload
        },
        setAirport: (state, action) => {
            state.FilterSearchForm.Airport.select = action.payload
        },
        setCustomers: (state, action) => {
            state.FilterSearchForm.Customers.select = action.payload
        },
        setAirline: (state, action) => {
            state.FilterSearchForm.Airline.select = action.payload
        },
        setRegistration: (state, action) => {
            state.FilterSearchForm.Registration.select = action.payload
        },
        setCreateInvoiceParams: (state, action) => {
            state.CreateInvoiceParams = action.payload
        },
        setPermissions: (state, action) => {
            state.Permissions = action.payload
        },
        setCalculatedServices: (state, action) => {
            state.CreateInvoiceParams.calculatedServices = action.payload
        },
    },
});

export const {
    setAccordionViewOn,
    setAccordionViewOff,
    setStageName,
    switchAccordionExpandedState,
    setStep,
    setFormData,
    setSelectItems,
    setFormDateRedux,
    setInvoiceStatus,
    setAirport,
    setCustomers,
    setAirline,
    setRegistration,
    setCreateInvoiceParams,
    setPermissions,
    setCalculatedServices
} = InvoiceGatFilterNavigationSlice.actions;
export default InvoiceGatFilterNavigationSlice.reducer;