import request from './request';
import * as paths from './paths';

class QualityModuleAPI {

  static qualityKpiList = async () => {
    return await request.get(paths.qualityKpiModule);
  };

  static qualityKpiSave = async (params) => {
    return await request.post(paths.qualityKpiModule + "/save" , params);
  };

  static qualityKpiUpdate = async (id,params) => {
    return await request.put(paths.qualityKpiModule + "/" +  id, params);
  };

  static qualityKpiById = async (id) => {
    return await request.get(paths.qualityKpiModule + "/" + id);
  };

  static search = async (params) => {
    return await request.post(paths.qualityKpiModule + "/search" ,params);
  };

  static weightDefinitionsSearch = async (params) => {
    return await request.post(paths.qMWeightDefinitions + "/search" ,params);
  };

  static weightDefinitionsSave = async (params) => {
    return await request.post(paths.qMWeightDefinitions + "/save" , params);
  };
}

export { QualityModuleAPI };
