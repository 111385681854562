import request from './request';
import * as paths from './paths';

class CustomerAgreement {
  static list = async () => {
    return await request.get(paths.list);
  };

  static mainById = async (id) => {
    return await request.get(paths.mainById + id);
  };

  static getAllTreeById = async (id) => {
    return await request.get(paths.getAllTreeById + id);
  };

  static search = async (params) => {
    return await request.post(paths.search, params);
  };

  static save = async (params) => {
    return await request.post(paths.save, params);
  };

  static delete = async (id) => {
    return await request.delete(paths.deleteAgreement + id);
  };

  static partsByAgreementId = async (id) => {
    return await request.get(paths.partsByAgreementId + id);
  };

  static partDeleteById = async (id) => {
    return await request.delete(paths.partDeleteById + id);
  };

  static servicesByPartId = async (id) => {
    return await request.get(paths.servicesByPartId + id);
  };

  static serviceDeleteById = async (id) => {
    return await request.delete(paths.serviceDeleteById + id);
  };

  static periodsById = async (id) => {
    return await request.get(paths.periodsById + id);
  };

  static periodsByServiceId = async (id) => {
    return await request.get(paths.periodsByServiceId + id);
  };

  static periodDeleteById = async (id) => {
    return await request.delete(paths.periodDeleteById + id);
  };

  static periodDeleteRangeById = async (params) => {
    return await request.delete(paths.periodDeleteRangeById, params);
  };

  static pricingDeleteById = async (id) => {
    return await request.delete(paths.pricingDeleteById + id);
  };

  static pricingDeleteRangeById = async (params) => {
    return await request.delete(paths.pricingDeleteRangeById, params);
  };
}

export { CustomerAgreement };
