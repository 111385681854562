import request from './request';

import * as paths from './paths';
import {platforms} from "./paths";

class menu {
  /**
   *
   * list
   *
   * @static
   * @memberof menu
   */
  static list = async (params) => {
    return await request.get(paths.menuList, params);
  };
  /**
   *
   * menu by id
   *
   * @static
   * @memberof menu
   */
  static menuById = async (id) => {
    return await request.get(paths.menuById + id);
  };
  /**
   *
   * delete menu
   *
   * @static
   * @memberof menu
   */
  static delete = async (id) => {
    return await request.delete(paths.deleteMenu + id);
  };
  /**
   *
   * save menu
   *
   * @static
   * @memberof menu
   */
  static save = async (params) => {
    return await request.post(paths.saveMenu, params);
  };
  /**
   *
   * add role for menu
   *
   * @static
   * @memberof menu
   */
  static addRole = async (params) => {
    return await request.post(paths.addRole, params);
  };
  /**
   *
   * remove role for menu
   *
   * @static
   * @memberof menu
   */
  static removeRole = async (params) => {
    return await request.post(paths.removeRole, params);
  };
  /**
   *
   * parent menu list
   *
   * @static
   * @memberof menu
   */
  static parentMenuList = async (params) => {
    return await request.get(paths.parentMenuList, params);
  };
  /**
   *
   * child menu list
   *
   * @static
   * @memberof menu
   */
  static childMenuList = async (id) => {
    return await request.get(paths.childMenuList + id);
  };
  /**
   *
   * up menu
   *
   * @static
   * @memberof menu
   */
  static upMenu = async (params) => {
    return await request.post(paths.upMenu, params);
  };
  /**
   *
   * down menu
   *
   * @static
   * @memberof menu
   */
  static downMenu = async (params) => {
    return await request.post(paths.downMenu, params);
  };
  /**
   *
   * platforms
   *
   * @static
   * @memberof menu
   */
  static platforms = async (params) => {
    return await request.get(paths.platforms, params);
  };
}

export { menu };
