import { CustomerAgreement } from './customerAgreement';
import { AgreementType } from './agreementType';
import { ContextVariable } from './ContextVariable';
import { RoyaltyAgreement } from './royaltyAgreement';
import { AutoRenewal } from './autoRenewal';
import { StandardAgreement } from './standardAgreement';

/**
 * All api calls
 *
 * @class Agreement
 */
class AgreementAPI {
  static CustomerAgreement = CustomerAgreement;
  static StandardAgreement = StandardAgreement;
  static RoyaltyAgreement = RoyaltyAgreement;
  static AgreementType = AgreementType;
  static ContextVariable = ContextVariable;
  static AutoRenewal = AutoRenewal;
}

export { AgreementAPI };
