import request from './request';
import * as paths from './paths';

class ContextVariable {
  // GET Context Variables - Flight Functions
  static list = async () => {
    return await request.get(paths.contextVariable);
  };
}
export { ContextVariable };
