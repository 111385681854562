import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import useLocale from '../../../hooks/useLocale';

export default function LanguageSelector() {
    const { t } = useLocale();
    const { changeLocale, allLanguages } = useLocale();
    const [language, setLanguage] = useState();

    const handleLangChange = (e) => {
        setLanguage(e.target.value);
        changeLocale(e.target.value);
        localStorage.setItem("selectedLoginPageLanguage", e.target.value);
    };

    const translate = (value) => {
        let _t = `pages.Login.${value}`;
        return t(_t);
    };

    return (
        <Stack spacing={3} mt={4}>
            <Typography sx={{ color: 'text.secondary' }}>
                {translate('Change language')}
            </Typography>
            <FormControl fullWidth>
                <InputLabel>{translate('Language')}</InputLabel>
                <Select
                    value={language || ''}
                    label={translate('Language')}
                    onChange={handleLangChange}
                >
                    {allLanguages.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Stack>
    );
}
