import { styled } from '@mui/material/styles';
import {Container, Typography, Grid, Stack, Box} from '@mui/material';
import useResponsive from '../../../hooks/useResponsive';
import PageContainer from '../../../components/PageContainer';
import LoginForm from './LoginForm';
import CelebiLogo from '../../../assets/images/logo/logo.png';
import useLocale from '../../../hooks/useLocale';
import LanguageSelector from './LanguageSelector';
import LoginFooter from './LoginFooter';
import {useEffect} from "react";

const HeaderStyle = styled('header')(({ theme }) => ({
    top: 50,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'center',
    // [theme.breakpoints.up('md')]: {
    //     alignItems: 'flex-start',
    //     padding: theme.spacing(7, 5, 0, 7),
    // },
}));

// const SectionStyle = styled(Card)(({ theme }) => ({
//     width: '100%',
//     maxWidth: 464,
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     margin: theme.spacing(2, 0, 2, 2),
// }));

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0),
}));

const PaperStyled = styled('div')(({ theme }) => ({
    backgroundColor: '#32527B',
    minHeight: '100vh',
    border: 'none',
    boxShadow: 'none',
    [theme.breakpoints.down('md')]: {
        height: '50px',
    },
    zIndex:2
}));

export default function Login() {
    const mdUp = useResponsive('up', 'md');

    const { t } = useLocale();

    const translate = (value) => {
        let _t = `pages.Login.${value}`;
        return t(_t);
    };

    useEffect(() => {
        localStorage.removeItem("selectedLoginPageLanguage");
    }, []);

    return (
        <PageContainer title={translate('Sign In')}>
            <Grid container alignContent='center' justifyContent='center'>
                <Grid item md={3} xs={12}>
                    {mdUp ? (
                        <PaperStyled variant='outlined' square elevation={5}>
                            <Box
                                display='flex'
                                justifyContent='center'
                                alignItems='center'
                                minHeight='100vh'
                            >
                                <Stack
                                    direction='column'
                                    justifyContent='center'
                                    alignItems='center'
                                    spacing={6}
                                >
                                    <img
                                        width={240}
                                        height={96}
                                        src={CelebiLogo}
                                        alt=''
                                    />
                                    <Typography
                                        variant='h2'
                                        sx={{ color: '#fff' }}
                                    >
                                        IKARUS
                                    </Typography>
                                </Stack>
                            </Box>
                        </PaperStyled>
                    ) : (
                        <HeaderStyle>
                            <img
                                width={150}
                                height={60}
                                src={CelebiLogo}
                                alt=''
                            />
                        </HeaderStyle>
                    )}
                </Grid>
                <Grid item md={9}>
                    <Container maxWidth='sm'>
                        <ContentStyle>
                            <Stack
                                direction='row'
                                alignItems='center'
                                sx={{ mb: 5 }}
                            >
                                <Box sx={{ flexGrow: 1 }}>
                                    <Typography
                                        variant='h4'
                                        mb={6}
                                        textAlign='center'
                                    >
                                        {translate('Sign In')}
                                    </Typography>
                                    <Typography
                                        sx={{ color: 'text.secondary' }}
                                    >
                                        {translate(
                                            'You can login to the system with your username and password.'
                                        )}
                                    </Typography>
                                </Box>
                            </Stack>
                            <LoginForm />
                            <LanguageSelector />
                            <LoginFooter />
                        </ContentStyle>
                    </Container>
                </Grid>
            </Grid>
        </PageContainer>
    );
}
