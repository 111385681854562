import React, { createContext, useState, useContext, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { enUS, trTR } from '@mui/material/locale';
import enLocale from 'date-fns/locale/en-GB';
import trLocale from 'date-fns/locale/tr';
import huLocale from 'date-fns/locale/tr';
import trIcon from '../assets/images/icons/tr.svg';
import enIcon from '../assets/images/icons/gb.svg';

const LANGS = [
  {
    label: 'English',
    value: 'en',
    dateFormat : 'en-GB',
    dateLocale: enLocale,
    systemValue: enUS,
    icon: enIcon,
  },
  {
    label: 'Türkçe',
    value: 'tr',
    dateFormat : 'tr-TR',
    dateLocale: trLocale,
    systemValue: trTR,
    icon: trIcon,
  },
  {
    label: 'Magyar',
    value: 'hu',
    dateFormat : 'tr-TR',
    dateLocale: huLocale,
    systemValue: trTR,
    icon: trIcon,
  },
];

const LocaleContext = createContext({});

export const LocaleProvider = ({ children }) => {
  const { i18n, t } = useTranslation();
  const cookieLanguage = localStorage.getItem('i18nextLng') || 'en';
  const currentLanguage = LANGS.find((_lang) => _lang.value === cookieLanguage);
  const [locale, setLocale] = useState(cookieLanguage);

  i18n.locale = locale;

  const changeLocale = useCallback(
    (localeParam) => {
      setLocale(localeParam);
      i18n.changeLanguage(localeParam);
    },
    [i18n]
  );

  const value = useMemo(() => {
    return {
      t,
      locale,
      currentLanguage,
      changeLocale,
      allLanguages: LANGS,
    };
  }, [t, locale, currentLanguage, changeLocale]);

  return <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>;
};

export default function useLocale() {
  const context = useContext(LocaleContext);

  return context;
}
