export const AgreementStages = {
  // Custotmer Agreement
  CAlist: 'CAlist',
  CAmain: 'CAmain',
  CApart: 'CApart',
  CAservice: 'CAservice',

  // Standart Agreement
  SAlist: 'SAlist',
  SAmain: 'SAmain',
  SApart: 'SApart',
  SAservice: 'SAservice',

  // Royalty Agreement
  RAlist: 'RAlist',
  RAservice: 'RAservice',

  // Auto Renewal
  ARlist: 'ARlist',
  ARDetail: 'ARDetail',
};
