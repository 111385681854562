// https://ariya.github.io/tapdigit/

import _ from "lodash";
//import * as DOMPurify from 'dompurify';

// parse and find tokens for equations like : sin(pi/4) * sqrt(2) + 41
export default function TapDigit() {

};
TapDigit.Token = {
    Operator: 'Operator',
    RelationalOperator: 'RelationalOperator',
    Negation: 'Negation',
    Identifier: 'Identifier',
    Number: 'Number',
    String: 'String',
 };

 TapDigit.Lexer = function () {
    let expression = '',
        length = 0,
        index = 0,
        marker = 0,
        T = TapDigit.Token;

    function peekNextChar(nextNth = 0 ) {
        let idx = index;
        return ((idx+ nextNth < length) ? expression.charAt(idx+nextNth) : '\x00');
    }

    function getNextChar(nextNth = 1 ) {
        let ch = '\x00',
            idx = index, idxEnd = index+nextNth;
        if (idxEnd < length) {
            ch = expression.substring(idx,idxEnd);
            index += nextNth;
        }
        else{
            ch = expression.substring(idx);
            index = length;
        }
        return ch;
    }

    function isWhiteSpace(ch) {
        return (ch === '\u0009') || (ch === ' ') || (ch === '\u00A0');
    }

    function isLetter(ch) {
        return (ch >= 'a' && ch <= 'z') || (ch >= 'A' && ch <= 'Z');
    }

    function isDecimalDigit(ch) {
        return (ch >= '0') && (ch <= '9');
    }

    function createToken(type, value) {
        return {
            type: type,
            value: value,
            start: marker,
            end: index - 1
        };
    }

    function skipSpaces() {
        let ch;

        while (index < length) {
            ch = peekNextChar();
            if (!isWhiteSpace(ch)) {
                break;
            }
            getNextChar();
        }
    }

    function scanString(){
        let ch = peekNextChar(),idx;
        if(ch === '"' )
        {
            for(idx =1; ( peekNextChar(idx) !== '"') && idx+index < length;idx++);
            if(idx+index < length && peekNextChar(idx) === '"' )
            {
                getNextChar(); // eliminate first quote"
                let t = createToken(T.String , getNextChar(idx-1));
                getNextChar(); // eliminate last quote"
                return t;
            }
        }else if( ch === '\'')
        {
            for(idx =1; ( peekNextChar(idx) !== '\'') && idx+index < length;idx++);
            if(idx+index < length && peekNextChar(idx) === '\'' )
            {
                getNextChar(); // eliminate first quote'
                let t = createToken(T.String , getNextChar(idx-1));
                getNextChar(); // eliminate last quote'
                return t;
            }
        }
        return undefined;
    }

    function scanOperator() {
        let ch = peekNextChar(), ch2 = peekNextChar(1);
        switch (ch) {
            case '!':
                if(ch2 === '='){
                    return createToken(T.RelationalOperator , getNextChar(2));   // !=
               }
                else
                    return createToken(T.Negation, getNextChar());              // ! (Negation)
            case '<':
                return createToken(T.RelationalOperator, getNextChar((ch2 === '=')? 2:1)); //  <=  OR < 
            case '>':
                return createToken(T.RelationalOperator, getNextChar((ch2 === '=')? 2:1)); // >= OR >
            case '=':
                if(ch2 === '='){
                    return createToken(T.RelationalOperator, getNextChar(2)); // ==
                }else 
                    return createToken(T.Operator, getNextChar());   // =
            case '|':
                if(ch2 === '|') {
                    return createToken(T.RelationalOperator, getNextChar(2));} // ||
                else return undefined;
            case '&':
                if(ch2 === '&') {
                    return createToken(T.RelationalOperator, getNextChar(2));} // &&
                    else return undefined;
            case '+': case '-': case '*': case '/': case '(': case ')': case '^': case '%': case ';': case ',':
                return createToken(T.Operator , getNextChar());
            default:
                return undefined;
        }
    }

    function isIdentifierStart(ch) {
        return (ch === '_') || isLetter(ch);
    }

    function isIdentifierPart(ch) {
        return isIdentifierStart(ch) || isDecimalDigit(ch);
    }

    function scanIdentifier() {
        let ch, id;

        ch = peekNextChar();
        if (!isIdentifierStart(ch)) {
            return undefined;
        }

        id = getNextChar();
        while (true) {
            ch = peekNextChar();
            if (!isIdentifierPart(ch)) {
                break;
            }
            id += getNextChar();
        }

        return createToken(T.Identifier, id);
    }

    function scanNumber() {
        let ch, number;

        ch = peekNextChar();
        if (!isDecimalDigit(ch) && (ch !== '.')) {
            return undefined;
        }

        number = '';
        if (ch !== '.') {
            number = getNextChar();
            while (true) {
                ch = peekNextChar();
                if (!isDecimalDigit(ch)) {
                    break;
                }
                number += getNextChar();
            }
        }

        if (ch === '.') {
            number += getNextChar();
            while (true) {
                ch = peekNextChar();
                if (!isDecimalDigit(ch)) {
                    break;
                }
                number += getNextChar();
            }
        }

        if (ch === 'e' || ch === 'E') {
            number += getNextChar();
            ch = peekNextChar();
            if (ch === '+' || ch === '-' || isDecimalDigit(ch)) {
                number += getNextChar();
                while (true) {
                    ch = peekNextChar();
                    if (!isDecimalDigit(ch)) {
                        break;
                    }
                    number += getNextChar();
                }
            } else {
                ch = 'character ' + ch;
                if (index >= length) {
                    ch = '<end>';
                }
                throw new SyntaxError('Unexpected ' + ch + ' after the exponent sign');
            }
        }

        if (number === '.') {
            throw new SyntaxError('Expecting decimal digits after the dot sign');
        }

        return createToken(T.Number, number);
    }

    function reset(str) {
        expression = str;
        length = str.length;
        index = 0;
    }

    function next() {
        let token;

        skipSpaces();
        if (index >= length) {
            return undefined;
        }

        marker = index;

        token = scanNumber();
        if (typeof token !== 'undefined') {
            return token;
        }
        token = scanString();
        if (typeof token !== 'undefined') {
            return token;
        }
        token = scanOperator();
        if (typeof token !== 'undefined') {
            return token;
        }

        token = scanIdentifier();
        if (typeof token !== 'undefined') {
            return token;
        }


        throw new SyntaxError('Unknown token from character ' + peekNextChar());
    }

    function peek() {
        let token, idx;

        idx = index;
        try {
            token = next();
            if(token !== undefined && token !== null){
                delete token.start;
                delete token.end;
            }
        } catch (e) {
            token = undefined;
        }
        index = idx;
        return token;
    }

    return {
        reset: reset,
        next: next,
        peek: peek
    };
};

TapDigit.Parser = function (QBcontextVars = {}) {

    let lexer = new TapDigit.Lexer(),
        T =  TapDigit.Token;

    function matchOp(token, op) {
        return (typeof token !== 'undefined') &&
           ( token.type === T.Operator || token.type === T.Negation || token.type === T.RelationalOperator ) &&
            token.value === op;
    }

    // ArgumentList := Expression |
    //                 Expression ',' ArgumentList
    function parseArgumentList() {
        let token, expr, args = [];

        while (true) {
            expr = parseExpression();
            if (typeof expr === 'undefined') {
                // TODO maybe throw exception?
                break;
            }
            args.push(expr);
            token = lexer.peek();
            if (!matchOp(token, ',')) {
                break;
            }
            lexer.next();
        }

        return args;
    }

    // FunctionCall ::= Identifier '(' ')' ||
    //                  Identifier '(' ArgumentList ')'
    function parseFunctionCall(name) {
        let token, args = [];

        token = lexer.next();
        if (!matchOp(token, '(')) {
            throw new SyntaxError('Expecting ( in a function call "' + name + '"');
        }

        token = lexer.peek();
        if (!matchOp(token, ')')) {
            args = parseArgumentList();
        }

        token = lexer.next();
        if (!matchOp(token, ')')) {
            throw new SyntaxError('Expecting ) in a function call "' + name + '"');
        }

        return {
            'FunctionCall' : {
                'name': name,
                'args': args
            }
        };
    }

    // Primary ::= Identifier |
    //             Number |
    //              String |
    //             '(' Assignment ')' |
    //             FunctionCall
    function parsePrimary() {
        let token, expr;

        token = lexer.peek();

        if (typeof token === 'undefined') {
            throw new SyntaxError('Unexpected termination of expression');
        }

        if (token.type === T.Identifier) {
          token = lexer.next();
          let contextVar = _.find(QBcontextVars, { variableName: token.value });
          
          if (contextVar) {
            if (contextVar.dataType === 6) {
              // function
              return parseFunctionCall(token.value);
            } else if (contextVar.dataType === 1 || contextVar.dataType === 4) {
              // True || Boolean
              return { Boolean: token.value };
            } else {
              return { Identifier: token.value };
            }
          }else if (token.value === 'IsInList') {
            return parseFunctionCall(token.value);
          }
          else return { Identifier: token.value };
        }

        if (token.type === T.Number) {
            token = lexer.next();
            return {
                'Number': token.value
            };
        }
        
        if (token.type === T.String) {
            token = lexer.next();
            return {
                'String': token.value
            };
        }

        if (matchOp(token, '(')) {
            lexer.next();
            expr = parseAssignment();
            token = lexer.next();
            if (!matchOp(token, ')')) {
                throw new SyntaxError('Expecting )');
            }
            return {
                'Expression': expr
            };
        }

        throw new SyntaxError('Parse error, can not process token ' + token.value);
    }

    // Unary ::= Primary |
    //           '-' Unary |
    //           '!' Negation 
    function parseUnary() {
        let token, expr;

        token = lexer.peek();
        if (matchOp(token, '-') || matchOp(token, '+')) {
            token = lexer.next();
            expr = parseUnary();
            return {
                'Unary': {
                    operator: token.value,
                    expression: expr
                }
            };
        }
        else if (matchOp(token,'!')){
            token = lexer.next();
            expr = parseUnary();
            return {
                'Negation': {
                    operator: token.value,
                    expression: expr
                }
            };
        }

        return parsePrimary();
    }

    // Multiplicative ::= Unary |
    //                    Multiplicative '*' Unary |
    //                    Multiplicative '/' Unary
    function parseMultiplicative() {
        let expr, token;

        expr = parseUnary();
        token = lexer.peek();
        while (matchOp(token, '*') || matchOp(token, '/')) {
            token = lexer.next();
            expr = {
                'Binary': {
                    operator: token.value,
                    left: expr,
                    right: parseUnary()
                }
            };
            token = lexer.peek();
        }
        return expr;
    }

    // Additive ::= Multiplicative |
    //              Additive '+' Multiplicative |
    //              Additive '-' Multiplicative
    function parseAdditive() {
        let expr, token;

        expr = parseMultiplicative();
        token = lexer.peek();
        while (matchOp(token, '+') || matchOp(token, '-')) {
            token = lexer.next();
            expr = {
                'Binary': {
                    operator: token.value,
                    left: expr,
                    right: parseMultiplicative()
                }
            };
            token = lexer.peek();
        }
        return expr;
    }

    // Relational ::= Additive |
    //                  Relational '==' Additive |
    //                  Relational '!=' Additive |
    //                   Relational '<' Additive |
    //                  Relational '<=' Additive |
    //                  Relational  '>' Additive |
    //                  Relational '>=' Additive 
    function parseRelational()
    {
        let expr, token;
        expr = parseAdditive();
        token = lexer.peek();

        while (matchOp(token, '<') || matchOp(token, '<=') || matchOp(token, '>') || matchOp(token, '>=')){
            token = lexer.next();
            expr = {
                'Relational': {
                    operator: token.value,
                    left: expr,
                    right: parseAdditive()
                }
            };
            token = lexer.peek();
        }
        return expr;
    }

    // Equality ::= Relational |
    //                  Equality '==' Relational |
    //                  Equality '!=' Relational |
    function parseEquality()
    {
        let expr, token;
        expr = parseRelational();
        token = lexer.peek();

        while (matchOp(token, '==') || matchOp(token, '!=') ){
            token = lexer.next();
            expr = {
                'Relational': {
                    operator: token.value,
                    left: expr,
                    right: parseRelational()
                }
            };
            token = lexer.peek();
        }
        return expr;
    }

    // LogicalAND ::= Equality |
    //          LogicalAND '&&' Equality |   
    function parseLogicalAnd()
    {
        let expr, token;
        expr = parseEquality();
        token = lexer.peek();

        while (matchOp(token, '&&')  ){
            token = lexer.next();
            expr = {
                'Logical': {
                    operator: token.value,
                    left: expr,
                    right: parseEquality()
                }
            };
            token = lexer.peek();
        }
        return expr;
    }

    // LogicalOR ::= LogicalAND |
    //          LogicalOR '||' LogicalAND |   
    function parseLogicalOr()
    {
        let expr, token;
        expr = parseLogicalAnd();
        token = lexer.peek();

        while (matchOp(token, '||')  ){
            token = lexer.next();
            expr = {
                'Logical': {
                    operator: token.value,
                    left: expr,
                    right: parseLogicalAnd()
                }
            };
            token = lexer.peek();
        }
        return expr;
    }


    // Assignment ::= Identifier '=' Assignment |
    //                LogicalOR
    function parseAssignment() {
        let token, expr;

        expr = parseLogicalOr();

        if (typeof expr !== 'undefined' && expr.Identifier) {
            token = lexer.peek();
            if (matchOp(token, '=')) {
                lexer.next();
                return {
                    'Assignment': {
                        name: expr,
                        value: parseAssignment()
                    }
                };
            }
            return expr;
        }

        return expr;
    }

    // Expression ::= Assignment
    function parseExpression() {
        return parseAssignment();
    }

    function parse(expression) {
        //expression = DOMPurify.sanitize(expression);
        let expr, token;

        lexer.reset(expression);
        expr = parseExpression();

        token = lexer.next();
        if (typeof token !== 'undefined') {
            throw new SyntaxError('Unexpected token ' + token.value);
        }

        return {
            'Expression': expr
        };
    }

    return {
        parse: parse
    };
};




TapDigit.Context = function () {
    let Constants, Functions;

    Constants = {
        pi: 3.14159,
        phi: 1.61803,
    };

    Functions = {
        abs: Math.abs,
        acos: Math.acos,
        asin: Math.asin,
        atan: Math.atan,
        ceil: Math.ceil,
        cos: Math.cos,
        exp: Math.exp,
        floor: Math.floor,
        ln: Math.ln,
        random: Math.random,
        sin: Math.sin,
        sqrt: Math.sqrt,
        tan: Math.tan
    };

    return {
        Constants: Constants,
        Functions: Functions,
        Variables: {}
    };
};

TapDigit.Evaluator = function (ctx) {

    let parser = new TapDigit.Parser(),
        context = (arguments.length < 1) ? new TapDigit.Context() : ctx;

    function exec(node) {
        let left, right, expr, args, i;

        if (node.hasOwnProperty('Expression')) {
            return exec(node.Expression);
        }

        if (node.hasOwnProperty('Number')) {
            return parseFloat(node.Number);
        }

        if (node.hasOwnProperty('Binary')) {
            node = node.Binary;
            left = exec(node.left);
            right = exec(node.right);
            switch (node.operator) {
            case '+':
                return left + right;
            case '-':
                return left - right;
            case '*':
                return left * right;
            case '/':
                return left / right;
            default:
                throw new SyntaxError('Unknown operator ' + node.operator);
            }
        }

        if (node.hasOwnProperty('Unary')) {
            node = node.Unary;
            expr = exec(node.expression);
            switch (node.operator) {
            case '+':
                return expr;
            case '-':
                return -expr;
            default:
                throw new SyntaxError('Unknown operator ' + node.operator);
            }
        }

        if (node.hasOwnProperty('Identifier')) {
            if (context.Constants.hasOwnProperty(node.Identifier)) {
                return context.Constants[node.Identifier];
            }
            if (context.Variables.hasOwnProperty(node.Identifier)) {
                return context.Variables[node.Identifier];
            }
            throw new SyntaxError('Unknown identifier');
        }

        if (node.hasOwnProperty('Assignment')) {
            right = exec(node.Assignment.value);
            context.Variables[node.Assignment.name.Identifier] = right;
            return right;
        }

        if (node.hasOwnProperty('FunctionCall')) {
            expr = node.FunctionCall;
            if (context.Functions.hasOwnProperty(expr.name)) {
                args = [];
                for (i = 0; i < expr.args.length; i += 1) {
                    args.push(exec(expr.args[i]));
                }
                return context.Functions[expr.name].apply(null, args);
            }
            throw new SyntaxError('Unknown function ' + expr.name);
        }

        throw new SyntaxError('Unknown syntax node');
    }

    function evaluate( expr) {
        let tree = parser.parse(expr);
        return exec(tree);
    }

    return {
        evaluate: evaluate
    };
};

TapDigit.Editor = function (element) {

    let input, editor, cursor, blinkTimer, lexer, hasFocus;

    function hideCursor() {
        if (blinkTimer) {
            window.clearInterval(blinkTimer);
        }
        blinkTimer = undefined;
        cursor.style.visibility = 'hidden';
    }

    function blinkCursor() {
        let visible = true;
        if (blinkTimer) {
            window.clearInterval(blinkTimer);
        }
        blinkTimer = window.setInterval(function () {
            cursor.style.visibility = visible ? '' : 'hidden';
            visible = !visible;
        }, 423);
    }

    // Get cursor position from the proxy input and adjust the editor
    function updateCursor() {
        let start, end, x, y, i, el, cls;

        if (typeof cursor === 'undefined') {
            return;
        }

        if (cursor.getAttribute('id') !== 'cursor') {
            return;
        }

        start = input.selectionStart;
        end = input.selectionEnd;
        if (start > end) {
            end = input.selectionStart;
            start = input.selectionEnd;
        }

        if (editor.childNodes.length <= start) {
            return;
        }

        el = editor.childNodes[start];
        if (el) {
            x = el.offsetLeft;
            y = el.offsetTop;
            cursor.style.left = x + 'px';
            cursor.style.top = y + 'px';
            cursor.style.opacity = 1;
        }

        // If there is a selection, add the CSS class 'selected'
        // to all nodes inside the selection range.
        cursor.style.opacity = (start === end) ? 1 : 0;
        for (i = 0; i < editor.childNodes.length; i += 1) {
            el = editor.childNodes[i];
            cls = el.getAttribute('class');
            if (cls !== null) {
                cls = cls.replace(' selected', '');
                if (i >= start && i < end) {
                    cls += ' selected';
                }
                el.setAttribute('class', cls);
            }
        }
    }

    // Get a new text from the proxy input and update the syntax highlight
    function updateEditor() {
        let expr, tokens, token, i, j, text, str, html;

        if (typeof lexer === 'undefined') {
            lexer = new TapDigit.Lexer();
        }

        tokens = [];
        try {
            expr = input.value;
            lexer.reset(expr);
            while (true) {
                token = lexer.next();
                if (typeof token === 'undefined') {
                    break;
                }
                tokens.push(token);
            }

            text = '';
            html = '';
            for (i = 0; i < tokens.length; i += 1) {
                token = tokens[i];
                j = 0;
                while (text.length < token.start) {
                    text += ' ';
                    html += '<span class="blank"> </span>';
                    j = 1;
                }
                str = expr.substring(token.start, token.end + 1);
                for (j = 0; j < str.length; j += 1) {
                    html += '<span class="' + token.type + '">';
                    html += str.charAt(j);
                    text += str.charAt(j);
                    html += '</span>';
                }
            }
            while (text.length < expr.length) {
                text += ' ';
                html += '<span class="blank"> </span>';
            }
        } catch (e) {
            // plain spans for the editor
            html = '';
            for (i = 0; i < expr.length; i += 1) {
                html += '<span class="error">' + expr.charAt(i) + '</span>';
            }
        } finally {
            html += '<span class="cursor" id="cursor">\u00A0</span>';
            if (html !== editor.innerHTML) {
                editor.innerHTML = html;
                cursor = document.getElementById('cursor');
                blinkCursor();
                updateCursor();
            }
        }
    }

    function focus() {
        window.setTimeout(function () {
            input.focus();
            blinkCursor();
            updateCursor();
        }, 0);
    }

    function blur() {
        input.blur();
    }

    function deselect() {
        let el, cls;
        input.selectionEnd = input.selectionStart;
        el = editor.firstChild;
        while (el) {
            cls = el.getAttribute('class');
            if (cls && cls.match('selected')) {
                cls = cls.replace('selected', '');
                el.setAttribute('class', cls);
            }
            el = el.nextSibling;
        }
    }

    function setHandler(el, event, handler) {
        if (el.addEventListener) {
            el.addEventListener(event, handler, false);
        } else {
            el.attachEvent('on' + event, handler);
        }
    }

    function resetHandler(el, event, handler) {
        if (el.removeEventListener) {
            el.removeEventListener(event, handler, false);
        } else {
            el.detachEvent('on' + event, handler);
        }
    }

    function onInputKeyDown(event) {
        updateCursor();
    }

    function onInputKeyUp(event) {
        updateEditor();
    }

    function onInputBlur() {
        hasFocus = false;
        hideCursor();
    }

    function onInputFocus() {
        hasFocus = true;
    }

    function onEditorMouseDown(event) {
        let x, y, i, el, x1, y1, x2, y2, anchor;

        deselect();

        x = event.clientX;
        y = event.clientY;
        for (i = 0; i < editor.childNodes.length; i += 1) {
            el = editor.childNodes[i];
            x1 = el.offsetLeft;
            x2 = x1 + el.offsetWidth;
            y1 = el.offsetTop;
            y2 = y1 + el.offsetHeight;
            if (x1 <= x && x < x2 && y1 <= y && y < y2) {
                input.selectionStart = i;
                input.selectionEnd = i;
                anchor = i;
                blinkCursor();
                break;
            }
        }

        // no match, then assume it is at the end
        if (i >= editor.childNodes.length) {
            input.selectionStart = input.value.length;
            input.selectionEnd = input.selectionStart;
            anchor = input.value.length;
        }

        function onDocumentMouseMove(event) {
            let i;
            if (event.target && event.target.parentNode === editor) {
                for (i = 0; i < editor.childNodes.length; i += 1) {
                    el = editor.childNodes[i];
                    if (el === event.target && el !== cursor) {
                        input.selectionStart = Math.min(i, anchor);
                        input.selectionEnd = Math.max(i, anchor);
                        blinkCursor();
                        updateCursor();
                        break;
                    }
                }
            }
            if (event.preventDefault) {
                event.preventDefault();
            }
            event.returnValue = false;
        }

        function onDocumentMouseUp(event) {
            if (event.preventDefault) {
                event.preventDefault();
            }
            event.returnValue = false;
            window.setTimeout(function () {
                resetHandler(document, 'mousemove', onDocumentMouseMove);
                resetHandler(document, 'mouseup', onDocumentMouseUp);
            }, 100);
        }

        focus();
        setHandler(document, 'mousemove', onDocumentMouseMove);
        setHandler(document, 'mouseup', onDocumentMouseUp);
        if (event.preventDefault) {
            event.preventDefault();
        }
        event.returnValue = false;
    }

    function setupDOM(element) {
        let container, wrapper;

        // Proxy input where we capture user keyboard interaction
        input = document.createElement('input');
        input.style.position = 'absolute';
        input.style.width = '100px';
        input.value = 'x = 40 + (6 / 3.0)';
        input.style.position = 'absolute';

        // Container for the above proxy, it also hides the proxy element
        container = document.createElement('div');
        container.appendChild(input);
        container.style.overflow = 'hidden';
        container.style.width = '1px';
        container.style.height = '0px';
        container.style.position = 'relative';

        // The "fake" editor
        editor = document.createElement('div');
        editor.setAttribute('class', 'editor');
        editor.style.wrap = 'on';
        editor.textContent = ' ';

        // Top-level wrapper for container
        wrapper = document.createElement('div');
        wrapper.appendChild(container);
        wrapper.appendChild(editor);
        element.appendChild(wrapper);

        // Wire all event handlers
        setHandler(input, 'keydown', onInputKeyDown);
        setHandler(input, 'keyup', onInputKeyUp);
        setHandler(input, 'blur', onInputBlur);
        setHandler(input, 'focus', onInputFocus);
        setHandler(editor, 'mousedown', onEditorMouseDown);
    }

   // hasFocus = false;
    setupDOM(element);
    updateEditor();

    return {
        focus: focus,
        blur: blur,
        deselect: deselect
    };
};

