import React, { useEffect, useState } from 'react';
import { ChevronLeft, Menu } from '@mui/icons-material';
import { HEADER, NAVBAR } from '../../config';
import CelebiLogo from '../../assets/images/logo/logo.png';
import { AppBar, Box, Drawer, IconButton, Stack, Toolbar, styled, Link, Typography } from '@mui/material';
import LanguagePopover from '../Header/LanguagePopover';
import AccountPopover from '../Header/AccountPopover';
import Scrollbar from '../Scrollbar';
import NavSection from './sections';
import Image from 'mui-image';
import AgreementConditionLoader from '../Header/AgreementConditionLoader';
import { useSelector } from 'react-redux';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

const drawerWidth = NAVBAR.DASHBOARD_WIDTH;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  borderWidth: 0,
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  borderWidth: 0,
  width: 0,
  [theme.breakpoints.up('sm')]: {
    width: 0,
  },
});

const DrawerHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  backgroundColor:
    theme.palette.mode === 'light' ? theme.palette.celebiDarkblue.main : theme.palette.background.default,
  ...theme.mixins.toolbar,
}));

const AppBarStyled = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  // backgroundColor: theme.palette.mode === 'light' ? theme.palette.celebiDarkblue.main : theme.palette.background.default,
  backgroundColor: process.env.REACT_APP_ENV === "test" ? theme.palette.celebiWarning.light : theme.palette.celebiDarkblue.main,
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
 
const DrawerStyled = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
    border: 'none',
  }),
}));

const drawerOpenKey = 'drawerOpen';

export default function PersistentDrawer({ isDrawerOpen }) {

  const [open, setOpen] = useState(isDrawerOpen);
  const menu = useSelector((state) => state.menu.menu);
  useEffect(() => {
    localStorage.setItem(drawerOpenKey, open);
  }, [open]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const renderContent = (
    <Scrollbar sx={{ height: 1, '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' } }}>
      <NavSection navConfig={menu} />
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle sx={{ display: 'flex' }}>
      <AppBarStyled position='fixed' open={open}>
        <Toolbar sx={{ minHeight: '100% !important', px: { lg: 5 } }}>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <Menu />
          </IconButton>
          {process.env.REACT_APP_ENV === "test" && 
            <Typography variant='h4'>TEST BUILD</Typography>
          }
          <Box sx={{ flexGrow: 1 }} />
          <Stack direction='row' alignItems='center' spacing={{ xs: 0.5, sm: 1.5 }}>
            <AgreementConditionLoader />
            <LanguagePopover />
            <AccountPopover />
          </Stack>
        </Toolbar>
      </AppBarStyled>
      <DrawerStyled variant='permanent' open={open}>
        <DrawerHeader>
          <Box component={Link} href='/dashboard' underline='none'>
            <Image
              src={CelebiLogo}
              alt='celebi'
              fit='contain'
              sx={{ display: 'flex', maxWidth: '50%', justifyContent: 'center', alignItems: 'center' }}
            />
          </Box>
          <IconButton color='celebiOcean' onClick={handleDrawerClose}>
            {<ChevronLeft />}
          </IconButton>
        </DrawerHeader>
        {renderContent}
      </DrawerStyled>
    </RootStyle>
  );
}
