import React from 'react';
import {
    TableCell,
    TableSortLabel
} from "@mui/material";
import {Box} from "@mui/system";
import { visuallyHidden } from '@mui/utils';
import PropTypes from "prop-types";

export function TableHeadCell(props){
    const { children } = props;
    const sortHandler=()=>{
        let order = false;
        let col = props.columnKey;
        if(!props.tableOrder.hasOwnProperty(col)){
            order = "asc";
        }else if(props.tableOrder[col] && props.tableOrder[col][1]=="asc"){
            order = "desc";
        }else if(props.tableOrder[col] && props.tableOrder[col][1]=="desc"){
            order = false;
        }
        let orderobj = {};
        if(order) {
            orderobj[col] = [col, order];
        }
        props.onSortAction(order, orderobj);
    }
    const getOrder=(col)=>{
        return props.tableOrder.hasOwnProperty(col)?(props.tableOrder[col][1] === 'desc' ? 'sorted descending' : 'sorted ascending'):false;
    }

    return(
        <TableCell {...props} sortDirection={props.tableOrder.hasOwnProperty(props.columnKey) ? props.tableOrder[props.columnKey][1] : false}>
            <TableSortLabel active={props.tableOrder.hasOwnProperty(props.columnKey)} direction={props.tableOrder.hasOwnProperty(props.columnKey) ? props.tableOrder[props.columnKey][1] : ''}  onClick={()=>sortHandler()}>
                {children}
                {props.tableOrder.hasOwnProperty(props.columnKey) !== null  ? (
                    <Box component="span" sx={visuallyHidden}>{getOrder(props.columnKey)}</Box>
                ) : null}
            </TableSortLabel>
        </TableCell>
    )
}

TableHeadCell.propTypes = {
    columnKey: PropTypes.any,
    tableOrder: PropTypes.any,
    onSortAction:PropTypes.func
}
