// Customer Agreement
export const mainById = 'api/customerAgreementMain/';
export const list = 'api/customerAgreementMain';
export const getAllTreeById = 'api/customerAgreementMain/agreementId/';
export const search = 'api/customerAgreement/search';
export const save = 'api/customerAgreementMain/save';
export const deleteAgreement = 'api/customerAgreementMain/delete/';
export const agreementType = 'api/agreementType';
export const partsByAgreementId = 'api/agreementPartsByAgreement/';
export const partDeleteById = 'api/deleteAgreementPart/';
export const servicesByPartId = 'api/agreementServicesByAgreementPart/';
export const serviceDeleteById = 'api/deleteAgreementService/';
export const periodsById = 'api/agreementPeriod/';
export const periodsByServiceId = 'api/agreementPeriodsByAgreementService/';
export const periodDeleteById = 'api/deleteAgreementPeriod/';
export const periodDeleteRangeById = 'api/customerAgreementPeriodList/delete';
export const pricingDeleteById = 'api/deletePricingCondition/';
export const pricingDeleteRangeById = 'api/pricingConditionList/delete'

// Standard Agreement
export const saSearch = 'api/standardAgreements/search';
export const saSave = 'api/standardAgreement/save';
export const saDeleteAgreement = 'api/deleteStandardAgreement/';
export const saGetAgreementById = 'api/standardAgreement/';
export const saPartsByAgreementId = 'api/standardAgreementPartsByStandardAgreementPart/';
export const saPartDeleteById = 'api/deleteStandardAgreementPart/';
export const saServicesByPartId = 'api/findStandardAgreementServicesByStandardAgreementPart/';
export const saServiceDeleteById = 'api/deleteStandardAgreementService/';
export const saPeriodsByAgreementServiceId = 'api/standardAgreementPeriodsByStandardAgreementService/';
export const saPeriodDeleteById = 'api/deleteStandardAgreementPeriod/';
export const saPeriodDeleteRangeById = 'api/standardAgreementPeriodList/delete';
export const saPricingDeleteById = 'api/deleteStandardPricingCondition/';
export const saPricingDeleteRangeById = 'api/standardAgreementConditionList/delete';

// Royalty Agreement
export const raGetAll = 'api/royaltyAgreements';
export const raGetAllTreeById = 'api/royaltyAgreement/agreementId/';
export const raByCompanyId = 'api/royaltyAgreementsByCompany/';
export const raDeleteAgreement = 'api/deleteRoyaltyAgreement/';
export const raServiceByAgreementId = 'api/royaltyAgreementServicesByRoyaltyAgreement/';
export const raServiceDeleteById = 'api/deleteRoyaltyAgreementServices/';
export const raPeriodsByServiceId = 'api/royaltyAgreementPeriodsByRoyaltyAgreementService/';
export const raPeriodDeleteById = 'api/deleteRoyaltyAgreementPeriod/'
export const raPeriodDeleteRangeById = 'api/royaltyAgreementPeriodList/delete';
export const raSave = 'api/saveRoyaltyAgreement';
export const raPriceDeleteById = 'api/deleteRoyaltyPricingCondition/';
export const raPricingDeleteRangeById = 'api/royaltyAgreementConditionList/delete';

//Auto Renewal
export const autoRenewalScheduled = 'api/agreementManager/scheduled/companyIds';
export const autoRenewalSave = 'api/autoRenewal';
export const autoRenewalDelete = 'api/deleteAgreementManagerMainById/';

// Context Variable Controller
export const contextVariable = '/api/pe';
