import { useCallback, useEffect, useState } from 'react';
import { api } from '../services/api';

export default function usePermissions({ pageName }) {
  const [permissions, setPermissions] = useState([]);

  const getPermissions = useCallback(async () => {
    const { data = [], error } = await api.permissions.getPermissionByPage(pageName);

    if (error) return;

    setPermissions(data?.map((item) => item?.elementId));
  }, [pageName]);

  const hasPermission = useCallback((id) => permissions.includes(id), [permissions]);

  useEffect(() => {
    getPermissions();
  }, [getPermissions]);

  return { permissions, hasPermission };
}
