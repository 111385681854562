import PropTypes from 'prop-types';
import { List, ListItem, TextField } from '@mui/material';
import { useRef, useState } from 'react';
import TapDigit from './TapDigit';
import { Box, maxHeight } from '@mui/system';
import styled from '@emotion/styled';

export default function MathExpressionBox(props) {
  const { value, onChange, suggestions, error, helperText, ...others } = props;
  const [anchorEl, setAnchorEl] = useState(null); //for suggestionlist
  const filteredSuggestions1 = useRef(suggestions);
  const [suggestedToken, setSuggestedToken] = useState(null);
  const Expression = useRef(null);
  const parsedVal = useRef();
  const tapDigit = TapDigit;
  const parser = new tapDigit.Parser();
  const lexer = new tapDigit.Lexer();

  const handlePopoverOpen = (event) => {
    var selectionStart = event.target.selectionStart;
    var token,
      lastToken = null,
      selectedToken = null;
    try {
      if (!event.target.value) {
        setAnchorEl(event.currentTarget);
        filteredSuggestions1.current = suggestions;
        setSuggestedToken(null);
        return;
      }
      lexer.reset(event.target.value);
      while ((token = lexer.next()) !== undefined) {
        if (
          selectionStart >= token.start &&
          selectionStart <= token.end + 1 &&
          token.type === 'Identifier'
        ) {
          selectedToken = token;
          break;
        }
        lastToken = token;
      }

      if (selectedToken) {
        filteredSuggestions1.current = suggestions.filter(
          (suggestion) => suggestion.toLowerCase().indexOf(selectedToken.value.toLowerCase()) > -1
        );
      } else if (selectionStart > lastToken.end + 1) {
        selectedToken = lastToken;
        filteredSuggestions1.current = suggestions;
      }

      // assign selected token to suggestion
      if (selectedToken && lastToken?.type !== 'Identifier' && lastToken?.type !== 'Number') {
        setAnchorEl(event.currentTarget);
        if (selectedToken.type === 'Identifier') setSuggestedToken(selectedToken);
      } else {
        setAnchorEl(null); //not on an İdentifier or syntax error
        setSuggestedToken(null);
      }
    } catch (error) {
      console.log(error);
      setAnchorEl(null); //not on an İdentifier
      setSuggestedToken(null);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const onChangeLocal = (e) => {
    var newVal = e.target.value;
    // var token;
    // var selectionStart = e.target.selectionStart;
    // var selectionEnd = e.target.selectionEnd;
    // lexer.reset(newVal);
    // while ((token = lexer.next()) !== undefined) {
    //   // console.log(token);
    // }
    try {
      parsedVal.current = parser.parse(newVal);
    } catch {
      //TODO: Parsing failed Inform user
    }

    onChange(newVal); //Send value to remote caller.
  };

  const onKeyUp = (event) => {
    if (event.code === 'Escape') {
      handlePopoverClose();
    } else if (event.code === 'Enter') {
      if (suggestedToken) {
        var before = event.target.value.substring(0, suggestedToken.start);
        var after = event.target.value.substring(suggestedToken.end + 1, event.target.value.length);
        var newIdentifier =
          filteredSuggestions1.current?.length > 0 ? filteredSuggestions1.current[0] : '';
        event.target.value = before + newIdentifier + after;
        onChange(event.target.value);
        event.preventDefault();
        setSuggestedToken(null);
        handlePopoverClose();
      }
    } else {
      handlePopoverOpen(event);
    }
  };

  const onClickListItem = (event) => {
    var newValue = '';
    var text = event.target.innerText;
    if (suggestedToken) {
      var before = value.substring(0, suggestedToken.start);
      var after = value.substring(suggestedToken.end + 1, value.length);
      newValue = before + text + after;
    } else {
      newValue = value + text;
    }
    onChange(newValue);
    event.preventDefault();
    Expression.current.focus();
    setSuggestedToken(null);
    handlePopoverClose();
  };

  const dropDownStyle = {
    position: 'relative',
    display: props.fullWidth?'block':'inline-block',
  };

  //const dropDownContentStyle = {

  return (
    <>
      <div style={dropDownStyle}>
        <div>
          <TextField
            required
            error={error}
            helperText={helperText}
            inputRef={Expression}
            value={value}
            onKeyUp={onKeyUp}
            onChange={(e) => onChangeLocal(e)}
            {...others}
          />
        </div>
        <SuggestionList
          visibility={open}
          suggestions={filteredSuggestions1.current}
          onClickListItem={onClickListItem}
        />
      </div>
    </>
  );
}

MathExpressionBox.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  suggestions: PropTypes.array,
};

export const ListItemStyled = styled(ListItem)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.background.accordionHeader,
    color: 'white',
    // '& .MuiListItemIcon-root': {
    //   color: 'white',
    // },
  },
}));

function SuggestionList(props) {
  const { visibility, suggestions, onClickListItem } = props;

  return (
    <Box
      hidden={!visibility}
      sx={{
        marginTop: '4px',
        position: 'absolute',
        backgroundColor: '#f1f1f1',
        overflowY: 'auto',
        border: '1px solid #555',
        borderRadius: '4px',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        maxHeight: 300,
        // width: '50%',
        zIndex: 9999,
      }}
    >
      {suggestions && suggestions.length > 0 && (
        <List>
          {suggestions.map((suggestion, index) => {
            return (
              <ListItemStyled
                key={suggestion}
                onClick={onClickListItem}
                sx={{ borderBottom: '1px solid #b1b1b1' }}
              >
                {suggestion}
              </ListItemStyled>
            );
          })}
        </List>
      )}
    </Box>
  );
}

SuggestionList.propTypes = {
  visibility: PropTypes.bool.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClickListItem: PropTypes.func,
};
