import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, setMenu,  setPaths } from "../../../contexts/menu/menuSlice";
import { api } from "../../../services";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-hot-toast";

const getMenuObject = (param) => {
    return { title: param?.itemLabel, icon: param?.itemIcon, path: param?.itemTo };
  };

export default function MenuGenerator(params) {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.menu.loading);
    const getUserInfo = useCallback(async () => {
        dispatch( setLoading(true)); 
    
        const { data = {}, error } = await api.auth.userInfo();

        const rootMenuList = await data?.menuItemList?.map((item) => {
            const hasChildMenu = item?.childMenuItems?.length !== 0;
            const subheader = item?.itemLabel;
            const items = item?.childMenuItems?.map((childItem) => {
              return hasChildMenu ? getMenuObject(childItem) : getMenuObject(item);
            });
      
            return { subheader, items };
        });
      
        const _paths = [];
      
        await data?.menuItemList?.forEach((x) => {
        x?.childMenuItems?.forEach((y) => {
            _paths.push(y?.itemTo);
        });
        });
      
         dispatch(setPaths(_paths));
         dispatch(setMenu(rootMenuList));
    
        dispatch(setLoading(false));
    
        if (error) return;
    
        secureLocalStorage.setItem("user", JSON.stringify(data));
    
        
      }, [dispatch ]);


      useEffect(() => {
        if(!loading ){
          try{
          getUserInfo();
          }catch(e){
            console.log(e);
            toast.error("Connection Error");
          }
        }
      }, [loading,getUserInfo]);
    
    return <></>
};
