import request from './request';

import * as paths from './paths';

class AgreementType {
  static list = async () => {
    return await request.get(paths.agreementType);
  };
  
}

export { AgreementType };
