const local = (function () {
  const setData = function (key, obj) {
    const values = JSON.stringify(obj);
    localStorage.setItem(key, values);
  };

  const getData = function (key) {
    if (
      localStorage.getItem(key) != null &&
      localStorage.getItem(key) !== undefined
    ) {
      try {
        return JSON.parse(localStorage.getItem(key));
      } catch {
        return null;
      }
    } else {
      return null;
    }
  };

  const updateData = function (key, newData) {
    if (
      localStorage.getItem(key) != null &&
      localStorage.getItem(key) !== undefined
    ) {
      try {
        const oldData = JSON.parse(localStorage.getItem(key));
        for (let keyObj in newData) {
          oldData[keyObj] = newData[keyObj];
        }
        const values = JSON.stringify(oldData);
        localStorage.setItem(key, values);
      } catch (e) {
        return false;
      }
    } else {
      return false;
    }
  };

  const getCachedData = function (key) {
    try {
      const itemStr = localStorage.getItem(key);
      // if the item doesn't exist, return null
      if (!itemStr) {
        return null;
      }
      const item = JSON.parse(itemStr);
      const now = new Date();
      // compare the expiry time of the item with the current time
      if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key);
        return null;
      }
      return item.value;
    } catch {}
    return null;
  };

  const setCachedData = function (key, value, ttl) {
    const now = new Date();
    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  };

  return {
    set: setData,
    get: getData,
    update: updateData,
    getCache: getCachedData,
    setCache: setCachedData,
  };
})();

export default local;
