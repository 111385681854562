import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import {List, Box, ListSubheader} from '@mui/material';
import {NavListRoot} from './NavList';

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky
                                                                   disableGutters {...props} />)(({theme}) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: '#fff',
    transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shorter,
    }),
}));

export default function NavSection({navConfig, ...other}) {
    return (
        <Box {...other}>
            {navConfig?.map((group) => (
                <List key={group.subheader} disablePadding sx={{px: 2}}>
                    <ListSubheaderStyle>{group.subheader}</ListSubheaderStyle>

                    {group.items.map((list) => (
                        <NavListRoot key={list.title} list={list}/>
                    ))}
                </List>
            ))}
        </Box>
    );
}

NavSection.propTypes = {
    navConfig: PropTypes.array,
};
