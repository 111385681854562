import { useCallback, useState } from 'react';
import { MenuItem, Stack, Typography } from '@mui/material';
import useLocale from '../../hooks/useLocale';
import MenuPopover from '../MenuPopover';
import IconButton from '../IconButton';
import { api } from '../../services/api';
import toast from 'react-hot-toast';

export default function LanguagePopover() {
  const { changeLocale, currentLanguage, allLanguages } = useLocale();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setOpen(null);
  }, []);

  const handleChangeLanguage = useCallback(
    (language) => async () => {
      const { error } = await api.auth.saveSelectedLang({ language });

      if (error) return toast.error(error);

      changeLocale(language);

      handleClose();

      window.location.reload();
    },
    [changeLocale, handleClose]
  );

  return (
    <>
      <IconButton onClick={handleOpen} sx={{ width: 40, height: 40, ...(open && { bgcolor: 'action.selected' }) }}>
        {/* <LazyLoadImage disabledEffect src={currentLanguage.icon} alt={currentLanguage.label} /> */}
        <Typography color="#fff" fontSize="bold">
          {currentLanguage.value.toUpperCase()}
        </Typography>
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ mt: 1.5, ml: 0.75, width: 180, '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 } }}
      >
        <Stack spacing={0.75}>
          {allLanguages.map((option) => (
            <MenuItem key={option.value} selected={option.value === currentLanguage.value} onClick={handleChangeLanguage(option.value)}>
              {/* <LazyLoadImage disabledEffect alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} /> */}

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
