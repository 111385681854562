import { auth } from './auth';
import { role } from './role';
import { roleGroup } from './roleGroup';
import { menu } from './menu';
import { makerChecker } from './makerChecker';
import { permissions } from './permissions';

/**
 * All api calls
 *
 * @class api
 */
class api {
  /**
   *
   * Auth
   * @static
   * @memberof api
   */
  static auth = auth;
  /**
   *
   * Role
   * @static
   * @memberof api
   */
  static role = role;
  /**
   *
   * Role Group
   * @static
   * @memberof api
   */
  static roleGroup = roleGroup;
  /**
   *
   * Dynamic Menu
   * @static
   * @memberof api
   */
  static menu = menu;
  /**
   *
   * Maker Checker
   * @static
   * @memberof api
   */
  static makerChecker = makerChecker;
  /**
   *
   * Permissions
   * @static
   * @memberof api
   */
  static permissions = permissions;
}

export { api };
