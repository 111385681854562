import request from './request';
import * as paths from './paths';

class AutoRenewal {
  static scheduled = async (params) => {
    return await request.post(paths.autoRenewalScheduled, params);
  };

  static save = async (params) => {
    return await request.post(paths.autoRenewalSave, params);
  };

  static delete = async (id) => {
    return await request.delete(paths.autoRenewalDelete + id);
  };
}

export { AutoRenewal };
