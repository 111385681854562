import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedAgreementPartDetailsHeight: 0,
  selectedAgreementPriceTreeHeight: 0,
  selectedAgreementPriceDetailHeight: 0,
  customerList_s: [],   // {searchText: '', customerList: []}
  customerList_p: [],   // {pageNum: 0,     customerList: []}
};

export const agreementCommonSlice = createSlice({
  name: 'agreementCommon',
  initialState,
  reducers: {
    setSelectedAgreementPartDetailsHeight: (state, action) => {
      state.selectedAgreementPartDetailsHeight = action.payload;
    },
    setSelectedAgreementPriceTreeHeight: (state, action) => {
      state.selectedAgreementPriceTreeHeight = action.payload;
    },
    setSelectedAgreementPriceDetailHeight: (state, action) => {
      state.selectedAgreementPriceDetailHeight = action.payload;
    },
    setCustomerList_s: (state, action) => {
      if(action.payload.searchText === '') {
        return;
      }
      if(state.customerList_s.some((item) => item.searchText === action.payload.searchText)) {
        state.customerList_s = state.customerList_s.map((item) => {
          if(item.searchText === action.payload.searchText) {
            return {searchText: action.payload.searchText,customerList: action.payload.customerList};
          }else {
            return item;
          }
        });
      }else {
      state.customerList_s.push({searchText: action.payload.searchText,customerList: action.payload.customerList});
      }
    },
    setCustomerList_p: (state, action) => {
      if(state.customerList_p.some((item) => item.pageNum === action.payload.pageNum)) {
        state.customerList_p = state.customerList_p.map((item) => {
          if(item.pageNum === action.payload.pageNum) {
            return {pageNum: action.payload.pageNum,customerList: action.payload.customerList};
          }else {
            return item;
          }
        });
      }else {
      state.customerList_p.push({pageNUm: action.payload.pageNum,customerList: action.payload.customerList});
      }
    },
  },
});

export const {
  setSelectedAgreementPartDetailsHeight,
  setSelectedAgreementPriceTreeHeight,
  setSelectedAgreementPriceDetailHeight,
} = agreementCommonSlice.actions;

export default agreementCommonSlice.reducer;
