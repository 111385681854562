import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { FormStates } from "../../constants/formStates";

const initialState = {
  updateRecord: null,
  lastSavedRecord: null,
  saveResponseQualityKPI: null,
  formState: FormStates.unChanged,
  globalLoading: false,

  selectedQualityKPI: null,
  selectedQualityKPILoading: false,
  selectedkpiConditionsLoading: false,

  selectedQualityKPIPartDetailLoading: false,
  selectedQualityKPIPartIndex: -1,

  newQualityKPI: false,
  newQualityKPIPart: false,
  newQualityKPIPartDetails: null,
  qualityKPIList :[],
  kpiNumber: null,
  saveState: false,
  customer : null
};

function arrangeUpdateRecord(
  state,
  position,
  record,
  part = null
) {
  // KPI Main
  if (!state.updateRecord)
    state.updateRecord = { id: state.selectedQualityKPI.id };
  if (position === "qualityKpi") {
    state.updateRecord = { ...state.updateRecord, ...record };
    return;
  }
  //Condition Parts --
  if (!state.updateRecord.kpiConditions) {
    state.updateRecord = {
      ...state.updateRecord,
      ...{ kpiConditions: [] },
    };
  }
  if (position === 'new_part') {
    state.updateRecord.kpiConditions.push(record);
    return;
  }
  let partIndex = state.updateRecord.kpiConditions.findIndex(x => x.id === part.id);
  if(partIndex < 0) {
    state.updateRecord.kpiConditions.push({id : part.id});
    partIndex = state.updateRecord.kpiConditions.findIndex(x => x.id === part.id);
    console.assert(partIndex >= 0, "Part is not found");
  }
  if (position === 'part_update') {
    state.updateRecord.kpiConditions[partIndex] = {
      ...state.updateRecord.kpiConditions[partIndex],
      ...record,
    };
    return;
  }
}

export const selectedQualityKPISlice = createSlice({
  name: "selectedQualityKPI",
  initialState,
  reducers: {
    setQualityKPIList: (state, action) => {
      if (action.payload) {
        state.qualityKPIList = action.payload;
      }
    },
    createNewQualityKpi: (state, action) => {
      const { uniqueId, agreement } = action.payload || {
        uniqueId: null,
        agreement: null,
      };
      if (state.newQualityKPI === false) {
        //Create a new record just user not start add operation before otherwise continue the same add op.
        if (agreement) {
          //copy
          let newQualityKPI = {
            ...agreement,
            id: 'new_' + uniqueId,
            description:
              "Coppied One, Don't forget to save after you finish editing",
          };

          state.selectedQualityKpi = newQualityKPI;
          state.kpiNumber = newQualityKPI.id;
          arrangeUpdateRecord(state, 'qualitykpi', newQualityKPI);
           state.qualityKPIList.push(newQualityKPI);
        } else {
          // add
          state.selectedQualityKpi = {
            id: 'new_' + uniqueId,
            kpiConditions: [],
          };
          state.selectedQualityKpiId = 'new_' + uniqueId;
          state.newQualityKPIPartDetails = null;
          state.updateRecord = {}; // no id on insertion
          
        }
        state.qualityKPIList = null;
        state.formState = FormStates.modified;
        state.newQualityKPI = true;
      }
    },
    setSelectedQualityKPI: (state, action) => {
      if (action.payload) {
        state.selectedQualityKPI = action.payload;
        state.newQualityKPI = false;
      }
    },
    setSelectedQualityKPILoading: (state, action) => {
      state.selectedQualityKPILoading = action.payload;
    },
    setSaveResponseQualityKPI
    : (state, action) => {
      state.saveResponseQualityKPI
      = action.payload;
    },
    updateSelectedQualityKPI: (state, action) => {
      console.log(state, action.payload);
      state.selectedQualityKPI = {
        ...state.selectedQualityKPI,
        ...action.payload,
      };
      arrangeUpdateRecord(state, "qualityKpi", action.payload);
      state.formState = FormStates.modified;
    },
    setFormState: (state, action) => {
      state.formState = action.payload;
    },   
    setGlobalLoading: (state, action) => {
      state.globalLoading = action.payload;
    },   
    setSaveState: (state, action) => {
      state.saveState = action.payload;
    },
    setSelectedkpiConditionsLoading: (state, action) => {
      state.selectedkpiConditionsLoading = action.payload;
    },
    createNewQualityKPIPart: (state, action) => {
      const { uniqueId, part } = action.payload || {
        uniqueId: null,
        part: null,
      };
      const uniqueIdText = 'new_part_' + uniqueId;
      if (part) {
        let newPart = { ...part, id: uniqueIdText };
        //giveIdsPartServices({ part: newPart, uniqueId: uniqueId });
        state.newQualityKPIPartDetails = newPart;
      } else {
        state.newQualityKPIPartDetails = {
          id: uniqueIdText
        };
      }

      state.selectedQualityKPIPartIndex = -1;
      state.newQualityKPIPart = true;
    },
    saveNewQualityKPIPart: (state) => {
      arrangeUpdateRecord(state, 'new_part', state.newQualityKPIPartDetails);
      if (!state.selectedQualityKPI.kpiConditions)
        state.selectedQualityKPI.kpiConditions = [];
      state.selectedQualityKPI.kpiConditions.push({
        ...state.newQualityKPIPartDetails,
      });
      state.formState = FormStates.modified;
    },
    deleteQualityKPIPart: (state, action) => {
      const partIdToRemove = action.payload;
      const partToRemove = state.selectedQualityKPI.kpiConditions?.find(
        (item) => item.id === partIdToRemove
      );
      if (partToRemove) {
        state.selectedQualityKPI.kpiConditions =
          state.selectedQualityKPI.kpiConditions.filter(
            (item) => item.id !== partIdToRemove
          );
      }
    },
    updateSelectedQualityKPIPartDetails: (state, action) => {
      if (state.newQualityKPIPart) {
        state.newQualityKPIPartDetails = {
          ...state.newQualityKPIPartDetails,
          ...action.payload,
        };
      } else {
        const part =
          state.selectedQualityKPI.kpiConditions[
            state.selectedQualityKPIPartIndex
          ];
        arrangeUpdateRecord(state, 'part_update', action.payload, part);

        state.selectedQualityKPI.kpiConditions[
          state.selectedQualityKPIPartIndex
        ] = {
          ...state.selectedQualityKPI.kpiConditions[
            state.selectedQualityKPIPartIndex
          ],
          ...action.payload,
        };

        state.formState = FormStates.modified;
      }
    },
    setSelectedQualityKPIPartIndex: (state, action) => {
      state.selectedQualityKPIPartIndex = action.payload ?? -1;
      state.newQualityKPIPart = false;
      state.periodListForDelete = null;
    },
    setClearUpdateRecord: (state) => {
      state.updateRecord = null;
    },
    setCustomer: (state, action) => {
      state.customer = action.payload;
    },
    
  },
});

export const {
  setQualityKPIList,
  createNewQualityKpi,
  setSelectedQualityKPI,
  setSelectedQualityKPILoading,
  setSelectedkpiConditionsLoading,
  setSelectedQualityKPIPartDetailLoading,
  setSaveResponseQualityKPI,

  createNewQualityKPIPart,
  setSelectedQualityKPIPartIndex,
  saveNewQualityKPIPart,
  deleteQualityKPIPart,
  updateSelectedQualityKPI,
  updateSelectedQualityKPIPartDetails,
  setGlobalLoading,
  setFormState,
  setSaveState,
  setClearUpdateRecord,
  setCustomer
} = selectedQualityKPISlice.actions;

export default selectedQualityKPISlice.reducer;
