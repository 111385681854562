import local from '../utils/localStorageFunc';

export * from './api';
export * from './core';
export * from './agreement';
export * from './invoice';
export * from './concessionfee';
export * from './qualityModuleService';

//TODO: Do not use this function, it is consumeng local storage has a max size of 5MB
export const cachedApiCall = async ({ apiCall, apiCallArgs = [], localStorageName, ttl = { days: 1 }, dataParser = null } = {}) => {
    if (apiCall === undefined || localStorageName === undefined) {
        throw new Error(`Missing parameters in cachedApiCall`)
    }
    let apiData = await local.getCache(localStorageName);
    let finalError = null;
    if (apiData === null || apiData === undefined || Object.keys(apiData).length === 0) {
        const { data = {}, error } = (apiCallArgs?.length === 0 ? await apiCall() : await apiCall.apply(this, apiCallArgs));
        if (error) {
            finalError = error;
            return { data, finalError }
        };
        apiData = data;
        if (dataParser != null) {
            apiData = [];
            for (let i = 0; i < data.length; i++) {
                const element = data[i];
                apiData.push(dataParser(element));
            }
        }
        if (localStorageName) {
            local.setCache(localStorageName, apiData, ttl);
        }
    }
    //TODO: handle error from somewhere else
    return { data: apiData, error: finalError };
}
