import request from './request';
import * as paths from './paths';

class StandardAgreement {
  static search = async (params) => {
    return await request.post(paths.saSearch, params);
  };

  static save = async (params) => {
    return await request.post(paths.saSave, params);
  };

  static getAgreementById = async (id) => {
    return await request.get(paths.saGetAgreementById + id);
  };

  static deleteAgreement = async (id) => {
    return await request.delete(paths.saDeleteAgreement + id);
  };

  static partsByAgreementId = async (id) => {
    return await request.get(paths.saPartsByAgreementId + id);
  };

  static partDeleteById = async (id) => {
    return await request.delete(paths.saPartDeleteById + id);
  };

  static servicesByAgreementPartId = async (id) => {
    return await request.get(paths.saServicesByPartId + id);
  };

  static serviceDeleteById = async (id) => {
    return await request.delete(paths.saServiceDeleteById + id);
  };
  
  static periodsByAgreementServiceId = async (id) => {
    return await request.get(paths.saPeriodsByAgreementServiceId + id);
  };

  static periodDeleteById = async (id) => {
    return await request.delete(paths.saPeriodDeleteById + id);
  };

  static periodDeleteRangeById = async (params) => {
    return await request.delete(paths.saPeriodDeleteRangeById, params);
  };

  static pricingDeleteById = async (id) => {
    return await request.delete(paths.saPricingDeleteById + id);
  };
  static pricingDeleteRangeById = async (params) => {
    return await request.delete(paths.saPricingDeleteRangeById, params);
  };
}

export { StandardAgreement };
