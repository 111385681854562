import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { FormStates, SaveStates } from '../../../constants/formStates';

const initialState = {
  updateRecord: null,
  newAutoRenewal: false,
  autoRenewalList: null,
  autoRenewalAgreementList: null,
  listLoading: false,
  formState: FormStates.unChanged,
  selectedAutoRenewalDetails: null,
  selectedAutoRenewalId: null,
  selectedAutoRenewalAgreementParts: null,
  saveResponseAutoRenewal: null,
  lastSavedRecord: null,
};

const arrangeUpdateRecord = (state, record) => {
  if (!state.updateRecord) state.updateRecord = { id: state.selectedAutoRenewalId };

  state.updateRecord = { ...state.updateRecord, ...record };
  return;
};

export const selectedARSlice = createSlice({
  name: 'AutoRenewal',
  initialState,
  reducers: {
    createNewAutoRenewal: (state, action) => {
      const uniqueId = _.uniqueId('new_');
      state.selectedAutoRenewalDetails = { id: uniqueId };
      state.selectedAutoRenewalId = uniqueId;
      state.formState = FormStates.modified;
      state.selectedAutoRenewalAgreementParts = null;
      state.newAutoRenewal = true;
    },

    setAutoRenewalAgreementList: (state, action) => {
      state.autoRenewalAgreementList = action.payload;
    },

    setAutoRenewalList: (state, action) => {
      state.autoRenewalList = action.payload;
    },

    setAutoRenewalDetails: (state, action) => {
      state.selectedAutoRenewalDetails = action.payload;
      state.selectedAutoRenewalId = action.payload?.id;
      state.formState = FormStates.unChanged;
      state.saveState = SaveStates.idle;
      state.newAutoRenewal = false;
      state.updateRecord = null;
    },

    deleteAgreement: (state, action) => {
      const agreementIdToRemove = action.payload;
      const agreementToRemove = state.autoRenewalList?.find(
        (item) => item.id === agreementIdToRemove
      );
      console.log(agreementToRemove);
      if (agreementToRemove) {
        state.autoRenewalList = state.autoRenewalList.filter(
          (item) => item.id !== agreementIdToRemove
        );
      }
    },

    setAutoRenewalAgreementParts: (state, action) => {
      state.selectedAutoRenewalAgreementParts = action.payload;
    },

    updateSelectedAutoRenewal: (state, action) => {
      state.selectedAutoRenewalDetails = {
        ...state.selectedAutoRenewalDetails,
        ...action.payload,
      };
      arrangeUpdateRecord(state, action.payload);
      state.formState = FormStates.modified;
    },

    setFormState: (state, action) => {
      state.formState = action.payload;
    },

    setSaveState: (state, action) => {
      state.saveState = action.payload;
    },

    setSaveResponseAutoRenewal: (state, action) => {
      state.saveResponseAutoRenewal = action.payload;
    },

    setLastSavedRecord: (state, action) => {
      state.lastSavedRecord = action.payload;
    },
    setListLoading: (state, action) => {
      state.listLoading = action.payload;
    },

    setClearUpdateRecord: (state) => {
      state.updateRecord = null;
    },
  },
});

export const {
  setAutoRenewalList,
  setAutoRenewalAgreementList,
  setAutoRenewalDetails,
  deleteAgreement,
  setAutoRenewalAgreementParts,
  createNewAutoRenewal,
  updateSelectedAutoRenewal,
  setFormState,
  setSaveState,
  setSaveResponseAutoRenewal,
  setLastSavedRecord,
  setListLoading,
  setClearUpdateRecord,
} = selectedARSlice.actions;

export default selectedARSlice.reducer;
