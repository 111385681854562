import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import useLocale from '../../../hooks/useLocale'
import { useDispatch, useSelector } from 'react-redux';
import useSettings from '../../../hooks/useSettings';

import {ConcessionFeeAPI, CoreAPI, InvoiceAPI} from '../../../services';
import Helper from "../../../utils/helper";
import toast from "react-hot-toast";
import {TableRow, TableCell, Popper} from "@mui/material";
import Iconify from "../../../components/Iconify";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import secureLocalStorage from "react-secure-storage";
import IconButton from "../../../components/IconButton";
import {createTheme} from "@mui/material/styles";
import {setFFToggleEditKey,setFFToggleDuplicateKey} from "../../../contexts/concessionfee/concessionFeeSlice";
import {setGlobalLoading} from "../../../contexts/mainSlice";


export default function FFForm(props) {
    const { t } = useLocale();
    const { themeSize } = useSettings();
    //const themeSize = "small";
    const translate = (value) => {
        let _t = `ConcessionFee.FlightFactor.index.${value}`;
        return t(_t);
    };
    const action = ['Add', 'Edit', 'Duplicate', 'Search'];
    const form = useSelector((state)=> state.ConcessionFee.FlightFactor.Form);
    const data = useSelector((state)=> state.ConcessionFee.FlightFactor.Data)
    const constants = useSelector((state)=>state.ConcessionFee)
    const [airPortData, setAirPortData] = useState([])
    const [formdata, setFormData]=useState({...form});
    const [fieldsError, setFieldsError]=useState({})
    const [company, setCompany] = useState({});
    const UserInfo = JSON.parse(secureLocalStorage.getItem('user'))
    const dispatch = useDispatch();


    useEffect(()=>{
        CoreAPI.companies().then(res=>{
           setCompany(res.data[0]);
        });
        InvoiceAPI.operatedAirPorts(UserInfo.branch.code)
            .then(res => {
                if (res.error) toast.error(res.error)
                var array = []
                res.data.map(item => {
                    const list = { label: item?.iataCode, id: item?.id, description: item?.iataCode }
                    array.push(list)
                })
                setAirPortData(array)
            })
    },[]);
    useEffect(()=>{
        if(getAction()==1||getAction()==2){
            let itemdata = props.item;
            setForm([
                {name:'airport', value:getItemById(airPortData,itemdata.airportId)},
                {name:'loadType', value:getItemById(constants.LoadType,itemdata.loadType)},
                {name:'flightFactor', value:getItemById(constants.FlightFactorData,itemdata.flightFactor)},
                {name:'id', value:itemdata.id},
                {name:'value', value:itemdata.value}
            ]);
        }
    },[airPortData])

    useEffect(()=>{
        resetToDefault();
    },[props.resetForm])

    const getAction = () =>{
        let ret = null;
        action.map((e,i)=>{
           if(e==props.action){
               ret = i;
           }
        });
        return ret;
    }

    const getItemById = (val, id)=>{
        let item = null;
        val.map((e,i)=>{
            if(e.id == id) item = e;
        });
        return item;
    }

    const setForm = (payload)=>{
        let formvalues = JSON.parse(JSON.stringify(formdata));
        if(Array.isArray(payload)){
            payload.map((pl,i)=>{
                if (formvalues[pl.name] && formvalues[pl.name].hasOwnProperty('select')) {
                    formvalues[pl.name].select = pl.value;
                } else {
                    formvalues[pl.name] = pl.value;
                }
            });
        }else {
            if (formvalues[payload.name] && formvalues[payload.name].hasOwnProperty('select')) {
                formvalues[payload.name].select = payload.value;
            } else {
                formvalues[payload.name] = payload.value;
            }
        }
        if(getAction()==3) props.complete(formvalues);
        setFormData(formvalues);
    }

    const onResetForm = () => {
        setFormData({...form});
        props.complete({});
    }

    const resetToDefault=()=>{
        setFormData({...form});
    }

    const onSubmit = () =>{
        dispatch(setGlobalLoading({open:false, info:''}));
        let formvalues = JSON.parse(JSON.stringify(formdata));
        let errors = {};
        setFieldsError(errors);
        let postparams = {};
        let validcheck = ["airport", "flightFactor", "loadType", "value"];
        let errmsg = null;
        Object.keys(formdata).map((e,i)=>{
            let validate = validcheck.find((elm)=>elm==e);
            switch(e){
                case "airport":
                    if(formdata[e].select?.id!==undefined){
                        postparams['airportId'] = formdata[e].select?.id;
                    }else{
                        if(validate) errors[e]={ error:true };
                    }
                    break;
                case "flightFactor":
                case "loadType":
                    if(formdata[e].select?.id !==undefined){
                        postparams[e] = formdata[e].select?.id;
                    }else{
                        if(validate) errors[e]={ error:true };
                    }
                    break;
                /*case "icaoCodeType":
                    if(formdata[e].select?.id !==undefined){
                        icaoCodesResData.map((el, k)=>{
                            if(el.id == formdata[e].select?.id){
                                postparams[e] = el;
                            }
                        });
                    }else{
                        if(validate) errors[e]={ error:true };
                    }
                    break;*/
                case "id":
                    if(getAction()!=0&&getAction()!=2) postparams['id']=formdata[e];
                    break;
                default:
                    if(formdata[e]!=''||formdata[e]!=null){
                        postparams[e]=formdata[e];
                    }else{
                        if(validate) errors[e]={ error:true };
                    }
                    break;
            }
        });
        setFieldsError(errors);
        if(Object.keys(errors).length){
            errmsg = translate("Fields marked in red are required. Please check the form!");
            toast.error(errmsg);
            return;
        }else{
            dispatch(setGlobalLoading({open:false, info:t(`components.GlobalSpinner.Processing`)+'...'}));
            ConcessionFeeAPI.saveFF(postparams).then((res)=>{
                dispatch(setGlobalLoading({open:false, info:''}));
                if (res?.statusCode == 500 || res?.error) {
                    toast.error(res?.data?.message || res?.message || translate("An error occured!")+":"+res?.error)
                    return
                }
                if (!res?.data?.success) {
                    let errorsmessage = '';
                    res?.data?.errors?.map((e1,i1)=>{
                        errorsmessage += e1.message+'\n';
                    });
                    toast.error(translate('Flight factor can not be saved!')+'\n'+errorsmessage)
                    return
                }
                toast.success(translate("Flight factor saved successfully!"))
                setFormData({...form});
                if(getAction()==1||getAction()==2){
                    closeButton();
                }
                props.complete();

            });
        }
    }

    const closeButton = () => {
        dispatch(setFFToggleEditKey(null));
        dispatch(setFFToggleDuplicateKey(null));
    }

    const popperStyle = { popper: { keepMounted:true, style: {
                "& .MuiAutocomplete-listbox li":{
                    fontSize:"0.8rem !important",

                }
    } } }
    const textfieldvariant = "standard";
    const texfieldStyle = {
        "& .MuiInput-root": { fontSize:"0.8rem" },
        "& .MuiInputLabel-root": { fontSize:"0.8rem" }
    };
    const rowstyling = getAction()==3&&{
        "& .MuiTableCell-root":{ background:'none' }
    }

    return (
        <>
            {getAction()==3&&(<TableRow><TableCell colspan={'100%'}>{t(`ConcessionFee.index.Search In Table`)}</TableCell></TableRow>)}
            <TableRow sx={{background:"#EFEFEF", ...rowstyling}}>
                <TableCell>
                    <Autocomplete
                        disablePortal
                        options={airPortData}
                        size={themeSize}
                        renderInput={(params) => <TextField {...params} label={translate('Station')} variant={textfieldvariant} sx={texfieldStyle} error={fieldsError?.airport?true:false} />}
                        onChange={(e, newValue) => {
                            setForm({name:'airport', value:newValue})
                        }}
                        componentsProps={popperStyle}
                        value={formdata.airport.select}
                    />
                </TableCell>
                <TableCell>
                    <Autocomplete
                        disablePortal
                        options={constants.FlightFactorData}
                        size={themeSize}
                        renderInput={(params) => <TextField {...params} label={translate('Flight Factor')} variant={textfieldvariant} sx={texfieldStyle} error={fieldsError?.flightFactor?true:false} />}
                        onChange={(e, newValue) => {
                            setForm({name:'flightFactor', value:newValue})
                        }}
                        componentsProps={popperStyle}
                        value={formdata.flightFactor.select}
                    />
                </TableCell>
                <TableCell>
                    <Autocomplete
                        disablePortal
                        options={constants.LoadType}
                        size={themeSize}
                        renderInput={(params) => <TextField {...params} label={translate('Load Type')} variant={textfieldvariant} sx={texfieldStyle} error={fieldsError?.loadType?true:false} />}
                        onChange={(e, newValue) => {
                            setForm({name:'loadType', value:newValue})
                        }}
                        componentsProps={popperStyle}
                        value={formdata.loadType.select}
                    />
                </TableCell>
                <TableCell>
                    <TextField size="small" type="number" fullWidth name="value" value={formdata.value?formdata.value:''} label={translate('Value')} onChange={(e)=>{
                        setForm({name:'value', value:e.target.value})
                    }} variant={textfieldvariant} sx={texfieldStyle} />
                </TableCell>
                <TableCell>
                    {(getAction()!=3)&&<IconButton onClick={()=>onSubmit()}><Iconify icon="eva:save-fill" /></IconButton>}
                    {(getAction()==1||getAction()==2)&&<IconButton onClick={()=>closeButton()}><Iconify icon="gridicons:cross-circle" /></IconButton>}
                    {(getAction()==3)&&<><IconButton onClick={()=>{onResetForm()}} title={t(`ConcessionFee.index.Reset Form`)}><Iconify icon="eva:refresh-outline" /></IconButton></>}
                </TableCell>
            </TableRow>
        </>
    )
}
FFForm.propTypes = {
    item:PropTypes.any,
    action:PropTypes.any,
    complete:PropTypes.func,
    resetForm:PropTypes.bool
}