import { createSlice } from '@reduxjs/toolkit';
import secureLocalStorage from "react-secure-storage";
const UserInfo = JSON.parse(secureLocalStorage.getItem('user'))

const initialState = {
    ConcessionFee:{
        Data:{},
        CalculationData:{}
    },
    CeilingRates:{
        Data:[],
        step:0,
        Form:{
            airport: {
                select: [],
            },
            icaoCodeType:{
                select:[],
            },
            domIntType:{
                select:[],
            },
            scheduleType:{
                select:[]
            },
            serviceType: {
                select:[]
            },
            handlingType: {
                select:[]
            },
            company:null,
            minGuaranteedAmount:null, //MGA
            aeraTariff:null, //AERRA
            aeraTariffFactor:null, //ATF
            aocric:null, //AOCRIC
            aocrfc:null, //AOCRFC
            cfer:null,
            cfir:null,
            cf:null,//CF on GHA
            currency:{
                select:[]
            },
            id:null
        },
        toggleEditKey: null,
        toggleDuplicateKey: null,
        tableOrder:{},
        searchParams:{}
    },
    FlightFactor:{
        Data:[],
        step:0,
        Form:{
            airport: {
                select: [],
            },
            flightFactor: {
                select:[],
            },
            loadType: {
                select:[],
            },
            id:null,
            value:null
        },
        toggleEditKey: null,
        toggleDuplicateKey: null,
        tableOrder:{},
        searchParams:{}
    },
    Formula:{
        Data:[],
        step:0,
        Form:{
            parameter: null,
            airport: {
                select: [],
            },
            serviceType: {
                select:[],
            },
            customerCategory: {
                select:[]
            },
            id:null,
            formula:null,
            remarks:null
        },
        toggleEditKey: null,
        toggleDuplicateKey: null,
        tableOrder:{},
        searchParams:{}
    },
    ACFAODefinitions:{
        Data:[],
        step:0,
        Form:{
            parameter: null,
            airport: {
                select: [],
            },
            serviceType: {
                select:[],
            },
            customerCategory: {
                select:[]
            },
            id:null,
            formula:null,
            remarks:null
        },
        toggleEditKey: null,
        toggleDuplicateKey: null,
        tableOrder:{},
        searchParams:{}
    },
    Permissions: {},
    ServiceType: [{id:1, label:'MAIN'},{id:2, label:'EXTRA'}],
    DomInt: [{id:1, label:'DOM'},{id:2, label:'INT'}],
    HandlingType: [{id:1, label:'Ramp Handling'}, {id:2,label:'Full Handling'},{id:3,label:'Cargo Handling'},{id:4, label:'Extra Handling'}],
    LoadType:[{id:1, label:'Passenger'}, {id:2, label:'Cargo'}, {id:3, label:'P2C'}],
    FlightFactorData:[{id:0, label:'Normal'},{id:1, label:'Technical'},{id:2, label:'Ferry'},{id:3, label:'Transit'}],
    CustomerCategory:[{id:0, label:''},{id:1, label:"IC"},{id:2, label:"FC"},{id:3, label:"GHA"}]
}
export const ConcessionFeeSlice = createSlice({
    name: 'ConcessionFee',
    initialState,
    reducers:{
        setResultset: (state,action) => {
            state.ConcessionFee.Data = action.payload
        },
        setPermissions: (state, action) => {
            state.Permissions = action.payload
        },
        setCeilingRatesStep:(state,action)=>{
            state.CeilingRates.step=action.payload
        },
        setCeilingRatesOrder:(state,action)=>{
            state.CeilingRates.tableOrder=action.payload
        },
        setCeilingRatesSearchParams:(state,action)=>{
            state.CeilingRates.searchParams=action.payload
        },
        setCeilingRatesResultset:(state, action)=>{
            state.CeilingRates.Data=action.payload
        },
        setToggleEditKey: (state, action)=>{
            state.CeilingRates.toggleEditKey=action.payload
        },
        setToggleDuplicateKey: (state, action)=>{
            state.CeilingRates.toggleDuplicateKey=action.payload
        },
        setFFResultset:(state, action)=>{
            state.FlightFactor.Data=action.payload
        },
        setFFToggleEditKey: (state, action)=>{
            state.FlightFactor.toggleEditKey=action.payload
        },
        setFFToggleDuplicateKey: (state, action)=>{
            state.FlightFactor.toggleDuplicateKey=action.payload
        },
        setFFOrder:(state,action)=>{
            state.FlightFactor.tableOrder=action.payload
        },
        setFFSearchParams:(state,action)=>{
            state.FlightFactor.searchParams=action.payload
        },
        setFormulaResultset:(state, action)=>{
            state.Formula.Data=action.payload
        },
        setFormulaToggleEditKey: (state, action)=>{
            state.Formula.toggleEditKey=action.payload
        },
        setFormulaToggleDuplicateKey: (state, action)=>{
            state.Formula.toggleDuplicateKey=action.payload
        },
        setFormulaOrder:(state,action)=>{
            state.Formula.tableOrder=action.payload
        },
        setFormulaSearchParams:(state,action)=>{
            state.Formula.searchParams=action.payload
        },
        setACFAODefinitionsResultset:(state, action)=>{
            state.ACFAODefinitions.Data=action.payload
        },
        setACFAODefinitionsToggleEditKey: (state, action)=>{
            state.ACFAODefinitions.toggleEditKey=action.payload
        },
        setACFAODefinitionsToggleDuplicateKey: (state, action)=>{
            state.ACFAODefinitions.toggleDuplicateKey=action.payload
        },
        setACFAODefinitionsOrder:(state,action)=>{
            state.ACFAODefinitions.tableOrder=action.payload
        },
        setACFAODefinitionsSearchParams:(state,action)=>{
            state.ACFAODefinitions.searchParams=action.payload
        },
    }
});

export const {
    setResultset,
    setPermissions,
    setCeilingRatesOrder,
    setCeilingRatesSearchParams,
    setCeilingRatesResultset,
    setToggleEditKey,
    setToggleDuplicateKey,
    setFFOrder,
    setFFSearchParams,
    setFFResultset,
    setFFToggleEditKey,
    setFFToggleDuplicateKey,
    setFormulaOrder,
    setFormulaSearchParams,
    setFormulaResultset,
    setFormulaToggleEditKey,
    setFormulaToggleDuplicateKey,
    setACFAODefinitionsOrder,
    setACFAODefinitionsSearchParams,
    setACFAODefinitionsResultset,
    setACFAODefinitionsToggleEditKey,
    setACFAODefinitionsToggleDuplicateKey
} = ConcessionFeeSlice.actions;
export default ConcessionFeeSlice.reducer;