import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import React from 'react';

const RootStyle = styled(Box)(({ theme }) => ({
    display: 'flex',
    height: 120,
    alignItems: 'end',
    justifyContent: 'center',
}));

const currentYear = new Date().getFullYear();

export default function LoginFooter() {
    return (
        <RootStyle>
            <Typography
                component='p'
                sx={{
                    fontsize: 18,
                }}
            >
                ÇELEBİ Copyright ©{currentYear}
            </Typography>
        </RootStyle>
    );
}
