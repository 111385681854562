import request from './request';
import * as paths from './paths';

class RoyaltyAgreement {
  static getAll = async () => {
    return await request.get(paths.raGetAll);
  };

  static getAllTreeById = async (id) => {
    return await request.get(paths.raGetAllTreeById + id);
  };

  static agreementByCompanyId = async (id) => {
    return await request.get(paths.raByCompanyId + id);
  };

  static deleteAgreement = async (id) => {
    return await request.delete(paths.raDeleteAgreement + id);
  }

  static serviceByAgreementId = async (id) => {
    return await request.get(paths.raServiceByAgreementId + id);
  };

  static serviceDeleteById = async (id) => {
    return await request.delete(paths.raServiceDeleteById + id);
  }

  static periodDeleteById = async (id) => {
    return await request.delete(paths.raPeriodDeleteById + id);
  };

  static periodDeleteRangeById = async (params) => {
    return await request.delete(paths.raPeriodDeleteRangeById, params);
  };

  static save = async (params) => {
    return await request.post(paths.raSave, params);
  };

  static pricingDeleteById = async (id) => {
    return await request.delete(paths.raPriceDeleteById + id);
  };
  static pricingDeleteRangeById = async (params) => {
    return await request.delete(paths.raPricingDeleteRangeById, params);
  };
}

export { RoyaltyAgreement };
