import { createSlice } from "@reduxjs/toolkit";



const initialState = {
    menu : [],
    paths : [],
    loading : false,
    menuData : [],
  };

export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setMenu: (state, action) => {
            state.menu = action.payload;
        },
        setPaths: (state, action) => {
            state.paths = action.payload;
        }   ,
        setLoading: (state, action) => {
            state.loading = action.payload;
        }   ,
        setMenuData: (state, action) => {
            state.menuData = action.payload;
        }
    },
});

export const { setMenu, setPaths, setLoading, setMenuData } = menuSlice.actions;
export default menuSlice.reducer;