import React from 'react';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';

export default function Button({ onClick, fullWidth, variant, children, loading, sx, size = 'large', ...other }) {
  return (
    <LoadingButton onClick={onClick} fullWidth={fullWidth} size={size} sx={sx} variant={variant} loading={loading} {...other}>
      {children}
    </LoadingButton>
  );
}

PropTypes.Button = {
  onClick: PropTypes.func,
  sx: PropTypes.object,
  fullWidth: PropTypes.bool,
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
  loading: PropTypes.bool,
};
