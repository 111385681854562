import { CheckCircleOutline } from '@mui/icons-material';
import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { stateNames } from '../../constants/stateNames';
import { loadConditionData } from '../../contexts/agreement/conditionSlice';

export default function AgreementConditionLoader(params) {
    const store = useStore();
    const dispatch = useDispatch();
    const conditionReadyState = useSelector(state => state.condition.readyState);

    useEffect(() => {
        if (conditionReadyState === stateNames.needToLoad) {
            try{
            loadConditionData(store.getState(),dispatch );
            }catch(e){
               // console.log(e);
            }
        }
    },[conditionReadyState, dispatch,store]);

    switch (conditionReadyState) {
        case stateNames.empty:
        case stateNames.needToLoad:
            return <></>;
        case stateNames.loading:
            return  <CircularProgress size={25} sx={{ marginRight: 2 }} title='Loading Conditions' />;
        case stateNames.error:
            return <IconButton size='small' onClick={params.onClick}/>;
        case stateNames.ready:
        default:
            return <></>;
    }
};
