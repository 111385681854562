import request from './request';

import * as paths from './paths';

class permissions {
  /**
   *
   * permission list by id
   *
   * @static
   * @memberof permissions
   */
  static list = (id) => {
    return request.get(paths.permList + id);
  };
  /**
   *
   * general permission list
   *
   * @static
   * @memberof permissions
   */
  static generalPermList = (id) => {
    return request.get(paths.permGeneralList);
  };
  /**
   *
   * permission by id
   *
   * @static
   * @memberof permissions
   */
  static permById = (id) => {
    return request.get(paths.permById + id);
  };
  /**
   *
   * delete permission
   *
   * @static
   * @memberof permissions
   */
  static deletePerm = (id) => {
    return request.delete(paths.deletePerm + id);
  };
  /**
   *
   * save permission
   *
   * @static
   * @memberof permissions
   */
  static savePerm = (params) => {
    return request.post(paths.savePerm, params);
  };
  /**
   *
   * save general permission
   *
   * @static
   * @memberof permissions
   */
  static saveGeneralPerm = (params) => {
    return request.post(paths.saveGeneralPerm, params);
  };
  /**
   *
   * add role to permission
   *
   * @static
   * @memberof permissions
   */
  static addRole = (params) => {
    return request.post(paths.addRoleForPerm, params);
  };
  /**
   *
   * remove role from permission
   *
   * @static
   * @memberof permissions
   */
  static removeRole = (params) => {
    return request.post(paths.removeRoleForPerm, params);
  };
  /**
   *
   * remove role from permission
   *
   * @static
   * @memberof permissions
   */
  static getPermissionByPage = (page) => {
    return request.get(paths.permissionByPage + page);
  };
}

export { permissions };
