import createAvatar from '../../utils/createAvatar';
import { Avatar as MyAvatar } from './Avatar';
import secureLocalStorage from "react-secure-storage";

export default function Avatar({ ...other }) {
  const user = JSON.parse(secureLocalStorage.getItem('user'));
  
  return (
      <MyAvatar
          // src={}
          alt={`${user?.firstName} ${user?.lastName} `}
          color={createAvatar(user?.firstName).color}
          {...other}
      >
        {createAvatar(user?.firstName).name}
      </MyAvatar>
  );
}
