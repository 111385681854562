import request from '../invoice/request';
import requestInvoice from '../invoice/requestInvoice';
import * as paths from './paths';
import {saveAllFFDefinitions, saveFFDefinitions} from "./paths";

class ConcessionFeeAPI{
    static getTable = async () => {
        return await requestInvoice.get(paths.getTable);
    };
    static acfiDefinitions = async () => {
        return await requestInvoice.get(paths.acfiDefinitions);
    };
    static saveAcfiDefinitions = async (params) => {
        return await requestInvoice.post(paths.saveAcfiDefinitions, params);
    };
    static deleteAcfiDefination = async (params) => {
        return await requestInvoice.delete(paths.deleteACFIDefinition+'/'+params);
    };
    static calculateConcessionFee = async (params) => {
        return await requestInvoice.post(paths.calculateConcessionFee, params);
    };
    static saveConcessionFee = async (params) => {
        return await requestInvoice.post(paths.saveConcessionFee, params);
    };
    static deleteConcessionFee = async (params) => {
        return await requestInvoice.delete(paths.deleteConcessionFee+'/'+ params);
    };
    static getIcaoCodeType = async () => {
        return await requestInvoice.get(paths.icaoCodeType);
    };
    static getFFTable = async () => {
        return await requestInvoice.get(paths.allFFDefinitions);
    }
    static saveFF = async (params) => {
        return await requestInvoice.post(paths.saveFFDefinitions, params);
    };
    static deleteFF = async (params) => {
        return await requestInvoice.delete(paths.deleteFfDefinitions+'/'+ params);
    };
    static acfaoDefinitions = async () => {
        return await requestInvoice.get(paths.acfaoDefinitions);
    };
    static saveAcfaoDefinitions = async (params) => {
        return await requestInvoice.post(paths.saveAcfaoDefinitions, params);
    };
    static deleteAcfaoDefinition = async (params) => {
        return await requestInvoice.delete(paths.deleteAcfaoDefinition+'/'+params);
    };

}

export { ConcessionFeeAPI }