import React, {useRef, useState} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {IconButton, InputAdornment, Link, Stack, TextField,} from '@mui/material';
import toast from 'react-hot-toast';
import Iconify from '../../../components/Iconify';
import {api} from '../../../services';
import Button from '../../../components/Button';
import useLocale from '../../../hooks/useLocale';
import secureLocalStorage from "react-secure-storage";
import { setMenu, setPaths } from '../../../contexts/menu/menuSlice';
import { useDispatch } from 'react-redux';
/* import ReCAPTCHA from "react-google-recaptcha"; */
import Turnstile, { useTurnstile } from "react-turnstile";
import { db } from '../../../indexedDB/db';

const getMenuObject = (param) => {
    return { title: param?.itemLabel, icon: param?.itemIcon, path: param?.itemTo };
  };

export default function LoginForm() {
    const navigate = useNavigate();
    const { t, changeLocale } = useLocale();
    const turnstile = useTurnstile();

    const [showPassword, setShowPassword] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [recaptchaVerified, setRecaptchaVerified] = useState(false);
    const dispatch = useDispatch();
    const onSubmit = async () => {
        setSubmitted(true);
        {/* 
        if (!recaptchaVerified) return toast.error(translate('RecaptchaNotVerified'));
        */} 
        if (!username || !password) return;

        setLoading(true);

        const { data = {}, error } = await api.auth.login({
            username,
            password,
        });

      setLoading(false);

      if (error) return toast.error(error);

      secureLocalStorage.setItem('token', data?.token);
      secureLocalStorage.setItem('refreshToken', data?.refreshToken);

      let language = localStorage.getItem("selectedLoginPageLanguage");
      if(language != null) {
          await api.auth.saveSelectedLang({ language });
      }

      const {data: optionsData = [], error: optionsError} =
        await api.auth.userInfo();

      if (optionsError) return;

      secureLocalStorage.setItem('user', JSON.stringify(optionsData));
      db.open();

      changeLocale(optionsData?.selectedLanguage ?? "en");
      const rootMenuList = await optionsData?.menuItemList?.map((item) => {
        const hasChildMenu = item?.childMenuItems?.length !== 0;
        const subheader = item?.itemLabel;
        const items = item?.childMenuItems?.map((childItem) => {
          return hasChildMenu ? getMenuObject(childItem) : getMenuObject(item);
        });
  
        return { subheader, items };
    });
  
    const _paths = [];
  
    await optionsData?.menuItemList?.forEach((x) => {
    x?.childMenuItems?.forEach((y) => {
        _paths.push(y?.itemTo);
    });
    });
  
     dispatch(setPaths(_paths));
     dispatch(setMenu(rootMenuList));
        navigate('/dashboard');
    };

    const enterKey = (e) => {
        if (e.key === 'Enter') {
            onSubmit();
        }
    };

    const translate = (value) => {
        let _t = `pages.Login.${value}`;
        return t(_t);
    };

    const recaptchaOnChange = (response) => {
        if(response) {
            api.auth.verifyRecaptcha({ response }).then(res => {
                if(res.data.success) {
                    setRecaptchaVerified(true);
                } else {
                    setRecaptchaVerified(false);
                    toast.error(translate('RecaptchaVerifyFailed'));
                    sleep(10000).then(() => {
                        turnstile.reset();
                    });
                }
            }).catch(err => {
                console.log(err);
                setRecaptchaVerified(false);
                toast.error(translate('RecaptchaVerifyFailed'));
                sleep(10000).then(() => {
                    turnstile.reset();
                });
            })
        } else {
            setRecaptchaVerified(false);
        }
    }

    function sleep(ms) {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        });
    }

    return (
        <>
            <Stack spacing={3}>
                <TextField
                    onKeyDown={enterKey}
                    name='username'
                    label={translate('Username')}
                    onChange={(e) => setUsername(e.target.value)}
                />

                <TextField
                    onKeyDown={enterKey}
                    name='password'
                    label={translate('Password')}
                    onChange={(e) => setPassword(e.target.value)}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <IconButton
                                    onClick={() =>
                                        setShowPassword(!showPassword)
                                    }
                                    edge='end'
                                >
                                    <Iconify
                                        icon={
                                            showPassword
                                                ? 'eva:eye-fill'
                                                : 'eva:eye-off-fill'
                                        }
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>
            
            <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                sx={{ my: 2 }}
            >
                {/*
                <Turnstile
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    theme="light"
                    retry="never"
                    language="en"
                    onVerify={recaptchaOnChange}
                />
                */} 
                <Link
                    component={RouterLink}
                    variant='subtitle2'
                    to={'/auth/forgot-password'}
                >
                    {translate('Forgot Password')}
                </Link>
            </Stack>
                       
            <Button
                onClick={onSubmit}
                loading={loading}
                size='large'
                color='celebiDarkblue'
                variant='contained'
                sx={{ mb: 3, marginBottom: 0 }}
            >
                {translate('Sign In')}
            </Button>
        </>
    );
}
