import Router from './routes';
import ThemeProvider from './utils/theme';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import ThemeColorPresets from './utils/theme/ThemeColorPresets';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { Toaster } from 'react-hot-toast';
import { LocaleProvider } from './hooks/useLocale';
import ThemeLocalization from './utils/theme/ThemeLocalization';
import { store } from './contexts/store'
import {Provider, useSelector} from 'react-redux'
import GlobalSpinner from "./components/GlobalSpinner/GlobalSpinner";


export default function App() {
  return (
    <Provider store={store}>   
      <ThemeProvider>
        <ThemeColorPresets>
          <LocaleProvider>
            <ThemeLocalization>
              <MotionLazyContainer>
                <Toaster position="top-right" />
                <ProgressBarStyle />
                <ScrollToTop />
                <Router />
                <GlobalSpinner></GlobalSpinner>
              </MotionLazyContainer>
            </ThemeLocalization>
          </LocaleProvider>
        </ThemeColorPresets>
      </ThemeProvider>
    </Provider>
  );
}
