import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import {
    Box,
    Divider,
    Typography,
    Stack,
    MenuItem,
    FormControlLabel,
    styled,
    Switch,
    InputLabel,
    FormControl,
    Select,
} from '@mui/material';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import Avatar from '../Avatar';
import MenuPopover from '../MenuPopover';
import IconButton from '../IconButton';
import useLocale from '../../hooks/useLocale';
import useSettings from '../../hooks/useSettings';
import { t } from 'i18next';
import secureLocalStorage from "react-secure-storage";
import { useDispatch } from 'react-redux';
import { clearAllSlices } from '../../contexts/clearSlice';
import { db } from '../../indexedDB/db';


const MENU_OPTIONS = [
    {
        label: t('components.Header.AccountPopover.Change Password'),
        linkTo: '/auth/reset-password',
    },
];

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 57,
    height: 29,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff'
                )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#D76935',
        width: 28,
        height: 28,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#fff'
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));

export default function AccountPopover() {
    const dispatch = useDispatch();
    const user = JSON.parse(secureLocalStorage?.getItem('user'));

    const { t } = useLocale();

    const { themeMode, onChangeMode } = useSettings();

    const { themeStretch, onToggleStretch } = useSettings();

    const { themeSize, onChangeSize } = useSettings();

    const navigate = useNavigate();

    const isMountedRef = useIsMountedRef();

    const [open, setOpen] = useState(null);

    const translate = (value) => {
        let _t = `components.Header.AccountPopover.${value}`;
        return t(_t);
    };

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleLogout = async () => {
        try {
            localStorage.clear();
            secureLocalStorage.clear();
            
            dispatch(clearAllSlices())
            await db.delete()
         
            navigate('/auth/login', { replace: true });

            if (isMountedRef.current) {
                handleClose();
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <IconButton
                onClick={handleOpen}
                sx={{
                    p: 0,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                        },
                    }),
                }}
            >
                <Avatar sx={{ bgcolor: '#0DACC9' }} />
            </IconButton>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{
                    p: 0,
                    mt: 1.5,
                    ml: 0.75,
                    '& .MuiMenuItem-root': {
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant='subtitle2' noWrap>
                        {user?.firstName} {user?.lastName}
                    </Typography>
                    <Typography variant='body2' sx={{ color: 'text.secondary' }} noWrap>
                        {user?.email}
                    </Typography>
                    <FormControlLabel
                        sx={{ m: 0, mt: 1 }}
                        control={
                            <MaterialUISwitch
                                value={themeMode === 'dark' ? 'light' : 'dark'}
                                onChange={onChangeMode}
                                checked={themeMode === 'dark'}
                            />
                        }
                        labelPlacement='start'
                        label={translate('Dark Mode')}
                    />
                </Box>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <FormControlLabel
                        sx={{ m: 0, mt: 1 }}
                        control={
                            <Switch
                                value={themeStretch === true ? false : true}
                                onChange={onToggleStretch}
                                checked={themeStretch === true}
                            />
                        }
                        label={translate('Stretch')}
                        labelPlacement='start'
                    />
                </Box>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <Stack direction='row' spacing={1} sx={{ alignItems: 'center', my: 1.5, px: 2.5 }}>
                    <Typography>Size</Typography>
                    <FormControl sx={{ minWidth: 100 }}>
                        <Select size='small' value={themeSize} onChange={onChangeSize}>
                            <MenuItem value={'small'}>Small</MenuItem>
                            <MenuItem value={'medium'}>Medium</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>

                {user?.ldap == false && (
                    <>
                        <Divider sx={{ borderStyle: 'dashed' }} />

                        <Stack sx={{ p: 1 }}>
                            {MENU_OPTIONS.map((option) => (
                                <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Stack>
                    </>
                )}

                <Divider sx={{ borderStyle: 'dashed' }} />

                <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
                    {translate('Logout')}
                </MenuItem>
            </MenuPopover>
        </>
    );
}
