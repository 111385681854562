import request from './request';

import * as paths from './paths';

class role {
  /**
   *
   * list
   *
   * @static
   * @memberof role
   */
  static list = (params) => {
    return request.get(paths.roleList, params);
  };
  /**
   *
   * role by id
   *
   * @static
   * @memberof role
   */
  static roleById = (id) => {
    return request.get(paths.roleById + id);
  };
  /**
   *
   * delete role
   *
   * @static
   * @memberof role
   */
  static delete = (id) => {
    return request.delete(paths.deleteRole + id);
  };
  /**
   *
   * save role
   *
   * @static
   * @memberof role
   */
  static save = (params) => {
    return request.post(paths.saveRole, params);
  };
}

export { role };
