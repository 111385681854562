import { combineReducers } from '@reduxjs/toolkit';
import CAselectedAgreementReducer, {
  setClearCustomerAgreementSlice,
} from './agreement/customer/selectedAgreementSlice';
import SAselectedAgreementReducer, {
  setClearStandardAgreementSlice,
} from './agreement/standard/selectedSASlice';
import RAselectedAgreementReducer, {
  setClearRoyaltyAgreementSlice,
} from './agreement/royalty/selectedRASlice';
import conditionReducer, { setClearConditionSlice } from './agreement/conditionSlice';

const clearSliceReducer = combineReducers({
  CAselectedAgreementReducer,
  SAselectedAgreementReducer,
  RAselectedAgreementReducer,
  conditionReducer,
});

export const clearAllSlices = () => (dispatch) => {
  dispatch(setClearCustomerAgreementSlice());
  dispatch(setClearStandardAgreementSlice());
  dispatch(setClearRoyaltyAgreementSlice());
  dispatch(setClearConditionSlice());
};

export default clearSliceReducer;
