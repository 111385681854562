import request from './request';

import * as paths from './paths';

class makerChecker {
  /**
   *
   * task list
   *
   * @static
   * @memberof makerChecker
   */
  static taskList = async (params) => {
    return await request.get(paths.taskList, params);
  };
  /**
   *
   * routing save
   *
   * @static
   * @memberof makerChecker
   */
  static routingSave = async (params) => {
    return await request.post(paths.routingSave, params);
  };
  /**
   *
   * makerChecker send request
   *
   * @static
   * @memberof makerChecker
   */
  static send = async (params) => {
    return await request.post(paths.requestSend, params);
  };
  /**
   *
   * makerChecker own request
   *
   * @static
   * @memberof makerChecker
   */
  static pageableList = async (params = {}) => {
    return await request.get(paths.requestOwnPage, {
      page: params?.page,
      size: params?.size
    });
  };
  /**
   *
   * makerChecker own request
   *
   * @static
   * @memberof makerChecker
   */
  static list = async (params) => {
    return await request.get(paths.requestOwn, params);
  };
  /**
   *
   * makerChecker checker list
   *
   * @static
   * @memberof makerChecker
   */
  static checkerList = async (params) => {
    return await request.get(paths.requestChecker, params);
  };
  /**
   *
   * makerChecker list
   *
   * @static
   * @memberof makerChecker
   */
  static makerCheckerList = async (id) => {
    return await request.get(`${paths.makerCheckerList}/${id}`);
  };
  /**
   *
   * makerChecker checker approve
   *
   * @static
   * @memberof makerChecker
   */
  static checkerApprove = async (params) => {
    return await request.post(paths.checkerApprove, params);
  };
  /**
   *
   * makerChecker checker reject
   *
   * @static
   * @memberof makerChecker
   */
  static checkerReject = async (params) => {
    return await request.post(paths.checkerReject, params);
  };
  /**
   *
   * makerChecker checker send back
   *
   * @static
   * @memberof makerChecker
   */
  static checkerSendBack = async (params) => {
    return await request.post(paths.checkerSendBack, params);
  };
  /**
   *
   * makerChecker checker send again
   *
   * @static
   * @memberof makerChecker
   */
  static checkerSendAgain = async (params) => {
    return await request.post(paths.checkerSendAgain, params);
  };
  /**
   *
   * makerChecker waiting
   *
   * @static
   * @memberof makerChecker
   */
  static waiting = async (params) => {
    return await request.get(`${paths.waiting}?id=${params?.id}&menuItemPath=${params?.path}`);
  };
}

export { makerChecker };
