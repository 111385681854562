//ConcessionFee path

export const getTable = 'api/concessionFeeTable';

export const acfaoDefinitions = 'api/acfaoDefinitions';
export const acfiDefinitions = 'api/acfiDefinitions';
export const calculateConcessionFee = 'api/calculateConcessionFee/{calculate}';
export const allFFDefinitions = 'api/allFFDefinitions';

export const saveAcfaoDefinitions = 'api/saveAcfaoDefinitions';
export const saveAcfiDefinitions = 'api/saveAcfiDefinition';
export const saveConcessionFee = 'api/saveConcessionFee';
export const saveAllFFDefinitions = 'api/saveAllFFDefinitions';
export const saveFFDefinitions = 'api/saveFFDefinitions';

export const icaoCodeType = 'api/icaoCodeType';

export const deleteFfDefinitions = 'api/deleteFfDefinitions';
export const deleteConcessionFee = 'api/deleteConcessionFee';
export const deleteACFIDefinition = 'api/deleteACFIDefinition';
export const deleteAcfaoDefinition = 'api/deleteACFAODefinition';
