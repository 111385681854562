import * as paths from './paths';
import { stateError } from './errors';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import axios from 'axios';

const axiosAuth = axios.create({
  baseURL: process.env.REACT_APP_AUTH_URL || 'https://auth-ikarustest.celebiaviation.com/',
  timeout: 120000,
  headers: { 'Accept-Language': 'tr-TR' },
});

axiosAuth.interceptors.request.use((config) => {
  const token = secureLocalStorage.getItem('token');
  const selectedLang = localStorage.getItem('i18nextLng') ?? 'en';

  if (!config.url.includes('public')) {
    config.headers.Authorization = token ? `Bearer ${token}` : '';
  }

  config.headers['Accept-Language'] = selectedLang;

  return config;
});

axiosAuth.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      secureLocalStorage.removeItem('token');
      secureLocalStorage.removeItem('refreshToken');
      secureLocalStorage.removeItem('user');
      window.location.reload();
    }

    return Promise.reject(error);
  });

const refreshAuthLogic = () =>
  axiosAuth
    .post(paths.refresh, {
      refreshToken: secureLocalStorage.getItem('refreshToken'),
      token: secureLocalStorage.getItem('token'),
    })
    .then((tokenRefreshResponse) => {
      if (tokenRefreshResponse) {
        secureLocalStorage.setItem('token', tokenRefreshResponse.data.accessToken);
        secureLocalStorage.setItem('refreshToken', tokenRefreshResponse.data.refreshToken);

        Promise.resolve();
      }
    })
    .catch(() => {
      secureLocalStorage.removeItem('token');
      secureLocalStorage.removeItem('refreshToken');
      secureLocalStorage.removeItem('user');
      useNavigate('auth/login'); // TODO: test this under 401 or 403 conditions
    });

createAuthRefreshInterceptor(axiosAuth, refreshAuthLogic);

class request {
  static async get(url = '', params = {}, headers = {}) {
    try {
      return await axiosAuth.get(url, { params, headers });
    } catch (error) {
      return stateError(error);
    }
  }

  static async post(url = '', body = {}, params = {}, headers = {}, responseType = 'json') {
    try {
      return await axiosAuth.post(url, body, { params, headers, responseType });
    } catch (error) {
      return stateError(error);
    }
  }

  static async put(url = '', body = {}, headers = {}) {
    if (Object.keys(body).length !== 0) {
      const params = this.setParams({ params: body });
      url = `${url}?${params}`;
    }

    try {
      return await axiosAuth.put(url, body, { headers });
    } catch (error) {
      return stateError(error);
    }
  }

  static async delete(url = '', data = {}, headers = {}) {
    try {
      return await axiosAuth.delete(url, { data, headers });
    } catch (error) {
      return stateError(error);
    }
  }

  static setParams({ params }) {
    return Object.entries(params)
      .map((e) => e.join('='))
      .join('&');
  }
}

export default request;
