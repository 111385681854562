import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enLocales from './en.json';
import trLocales from './tr.json';
import huLocales from './hu.json';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: { translations: enLocales },
            tr: { translations: trLocales },
            hu: { translations: huLocales}
        },
        lng: localStorage.getItem('i18nextLng') || 'en',
        fallbackLng: 'tr',
        debug: false,
        ns: ['translations'],
        defaultNS: 'translations',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
