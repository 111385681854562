import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { alpha, ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import componentsOverride from './overrides';

export default function ThemeColorPresets({ children }) {
  const defaultTheme = useTheme();

  const themeOptions = useMemo(
    () => ({
      ...defaultTheme,
      palette: {
        ...defaultTheme.palette,
        primary: {
          name: 'default',
          lighter: '#74cdd5',
          light: '#2dccd3',
          main: '#00abc9',
          dark: '#0070ba',
          darker: '#0070ba',
          contrastText: '#fff',
        },
      },
      customShadows: {
        ...defaultTheme.customShadows,
        primary: `0 8px 16px 0 ${alpha('#00abc9', 0.24)}`,
      },
    }),
    [defaultTheme]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

ThemeColorPresets.propTypes = {
  children: PropTypes.node,
};
