import { alpha } from '@mui/material/styles';

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const PRIMARY = {
  lighter: '#D1E9FC',
  light: '#76B0F1',
  main: '#2065D1',
  dark: '#103996',
  darker: '#061B64',
  contrastText: '#fff',
};

const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
  contrastText: '#fff',
};

const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800],
};

const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff',
};

const CELEBI_PRIMARY = {
  lighter: '#579eed',
  light: '#579eed',
  main: '#0070ba',
  dark: '#004589',
  darker: '#004589',
  contrastText: '#fff',
};

const CELEBI_OCEAN = {
  light: '#a7ffff',
  main: '#74cdd5',
  dark: '#3f9ca4',
  contrastText: '#fff',
};

const CELEBI_DARKBLUE = {
  light: '#617eaa',
  main: '#32527B',
  dark: '#002a4f',
  contrastText: '#fff',
};
const CELEBI_SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
  contrastText: '#fff',
};

const CELEBI_WARNING = {
  lighter: '#ff843c',
  light: '#ff843c',
  main: '#fe5000',
  dark: '#c20d00',
  darker: '#c20d00',
  contrastText: '#fff',
};

const CELEBI_ERROR = {
  lighter: '#ff6247',
  light: '#ff6247',
  main: '#da291c',
  dark: '#a00000',
  darker: '#a00000',
  contrastText: '#fff',
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

// BUTTON COLORS
const CELEBI_SAVE = {
  lighter: '#60ad5e',
  light: '#60ad5e',
  main: '#2e7d32',
  dark: '#005005',
  darker: '#005005',
  contrastText: '#fff',
};

const CELEBI_DELETE = {
  lighter: '#ff5f52',
  light: '#ff5f52',
  main: '#c62828',
  dark: '#8e0000',
  darker: '#8e0000',
  contrastText: '#fff',
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  celebiPrimary: { ...CELEBI_PRIMARY },
  celebiOcean: { ...CELEBI_OCEAN },
  celebiDarkblue: { ...CELEBI_DARKBLUE },
  secondary: { ...SECONDARY },
  celebiSecondary: { ...CELEBI_SECONDARY },
  celebiSave: { ...CELEBI_SAVE },
  celebiDelete: { ...CELEBI_DELETE },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  celebiWarning: { ...CELEBI_WARNING },
  error: { ...ERROR },
  celebiError: { ...CELEBI_ERROR },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
  background: { paper: '#fff', default: GREY[100], neutral: GREY[200] },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  celebiBlue: {
    Pantone300: '#0271b8',
    Pantone319: '#7fcbd4',
    Pantone3125: '#0DACC9',
  },
  celebiBlack: '#080a15',
  celebiCoolGray11: '#757474',
  celebiOrange021: '#e9540d',
  celebiRed: '#e11f1c',
};

const palette = {
  light: {
    ...COMMON,
    mode: 'light',
    text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
    background: {
      paper: '#fff',
      default: '#fff',
      neutral: GREY[200],
      accordionHeader: '#32527B',
      miniTitle: '#6392b8',
      datagridHeader: '#f3f6f8',
      datagridSelections: '#A3C7D6',
      treeviewSelections: '#bedce8',
      celebiSave: CELEBI_SAVE.main,
      modified: '#d4d283',
      insert: '#a2ba80',
      celebiOrange: '#e9540d',
      hasSub: '#C5C5C5',
      deleted: '#FF756A',
    },
    action: { active: GREY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    mode: 'dark',
    text: { primary: '#fff', secondary: GREY[500], disabled: GREY[600] },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: GREY[500_16],
      accordionHeader: '#32527B',
      miniTitle: '#6392b8',
      datagridHeader: '#161C24',
      datagridSelections: '#9F73AB',
      treeviewSelections: '#c18fcf',
      celebiSave: CELEBI_SAVE.contrastText,
      modified: '#737131',
      insert: '#4c612f',
      celebiOrange: '#FFA500',
      hasSub: '#696969',
      deleted: '#B96C6C',
    },
    action: { active: GREY[500], ...COMMON.action },
  },
};

export default palette;
