//paths

//auth
export const login = 'api/auth/login';
export const changePassword = 'api/user/resetPassword';
export const forgotPassword = 'api/public/user/forgotPassword';
export const publicResetPassword = 'api/public/user/resetPassword';
export const resetPassword = 'api/user/resetPassword';
export const own = 'api/own?platform=WEB';
export const refresh = 'api/auth/refreshtoken';
export const logout = 'api/auth/logout';
export const userActivate = 'api/public/user/activate';
export const userSave = 'api/user/save';
export const userById = 'api/user/';
export const userList = 'api/user';
export const userPageable = 'api/userPageable';
export const selectedLanguage = 'api/user/saveSelectedLanguage';
export const recaptcha = 'api/auth/recaptcha';
export const userAddRoleGroup = 'api/user/addRoleGroup';
export const userRemoveRoleGroup = 'api/user/removeRoleGroup';
export const branchList = 'api/branch';
export const departmentList = 'api/departments';
export const companyList = 'api/company';
export const addCompany = 'api/user/addCompany';
export const removeCompany = 'api/user/removeCompany';
export const sendActivationMail = 'api/user/sendActivationMail';

//role
export const roleList = 'api/role';
export const roleById = 'api/role/';
export const deleteRole = 'api/role/delete/';
export const saveRole = 'api/role/save';

//userRoleGroup
export const roleGroupList = 'api/userRoleGroup';
export const roleGroupById = 'api/userRoleGroup/';
export const deleteRoleGroup = 'api/userRoleGroup/delete/';
export const saveRoleGroup = 'api/userRoleGroup/save';
export const addRoleGroup = 'api/userRoleGroup/addRole';
export const removeRoleGroup = 'api/userRoleGroup/removeRole';
export const authorityTree = 'api/menuItemPermissionListByRoleId/';
export const saveAuthorityTree = 'api/saveMenuItemPermissionListByRoleId/';

//Dynamic Menu
export const menuList = 'api/menuItem';
export const menuById = 'api/menuItem/';
export const addRole = 'api/menuItem/addRole';
export const deleteMenu = 'api/menuItem/delete/';
export const removeRole = 'api/menuItem/removeRole';
export const saveMenu = 'api/menuItem/save';
export const parentMenuList = 'api/menuItem/parent';
export const childMenuList = 'api/menuItem/child/';
export const upMenu = 'api/menuItem/up';
export const downMenu = 'api/menuItem/down';
export const platforms = 'api/menuItem/platform';

//permission
export const permList = 'api/menuItemPermissionList/';
export const permGeneralList = 'api/menuItemGeneralPermissionList';
export const permById = 'api/menuItemPermission/';
export const deletePerm = 'api/menuItemPermission/delete/';
export const savePerm = 'api/menuItemPermission/save';
export const saveGeneralPerm = 'api/menuItemGeneralPermission/save';
export const addRoleForPerm = 'api/menuItemPermission/addRole';
export const removeRoleForPerm = 'api/menuItemPermission/removeRole';
export const permissionByPage = '/api/ownMenuItemPermission?menuItemPath=/';

//makerChecker

export const taskList = 'api/makerChecker/task';
export const routingSave = 'api/makerChecker/routing/save';
export const requestSend = 'api/makerChecker/request/send';
export const requestOwnPage = 'api/makerChecker/request/ownPage';
export const requestOwn = 'api/makerChecker/request/own';
export const makerCheckerList = 'api/makerChecker/request';
export const requestChecker = 'api/makerChecker/request/list';
export const checkerApprove = 'api/makerChecker/request/approve';
export const checkerReject = 'api/makerChecker/request/reject';
export const checkerSendBack = 'api/makerChecker/request/back';
export const checkerSendAgain = 'api/makerChecker/request/send';
export const waiting = 'api/makerChecker/request/waiting';

//airlineGroup

export const airlineGroup = 'api/airlineGroup';
