import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Iconify from '../Iconify';
import { t } from 'i18next';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmDialog({
  open,
  visible = false,
  children,
  setVisible,
  onClick,
  title,
  sx,
  isGiveUp = false,
  onGiveUp,
  giveUpText = t('components.ConfirmDialog.index.No'),
  confirmText = t('components.ConfirmDialog.index.Yes'),
  ...other
}) {
  return (
    <Dialog open={visible} TransitionComponent={Transition} onClose={() => setVisible(false)} aria-labelledby="confirm-dialog" sx={sx} {...other}>
      <DialogTitle id="confirm-dialog" sx={{ display: 'flex', alignItems: 'center' }}>
        {title}
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', alignItems: 'center', mt: 3, pb: 0 }}>
        <Iconify icon={'eva:alert-triangle-outline'} sx={{ mr: 2, color: 'warning.main' }} />
        {children}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={isGiveUp ? onGiveUp : () => setVisible(false)}>
          {giveUpText}
        </Button>
        <Button variant="contained" onClick={onClick} color="error">
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
