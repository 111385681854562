import { m } from 'framer-motion';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Button, Container, Typography } from '@mui/material';
import { ForbiddenIllustration } from '../../assets';
import { MotionContainer, varBounce } from '../../components/animate';
import { useSelector } from 'react-redux';

export default function RoleBasedGuard({children }) {
  const location = useLocation();
  const paths = useSelector((state) => state.menu.paths);
  if (paths?.length !== 0 && !paths.includes(`/${location.pathname.split('/')[1]}`) && location.pathname !== '/') {
    return (
      <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            Permission Denied
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>You do not have permission to access this page</Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>

        <Button to="/" size="large" variant="contained" component={RouterLink}>
          Back To Homepage
        </Button>
      </Container>
    );
  }

  return children;
}
