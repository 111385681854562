import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const initialState = {
  agreementClipboard: [],
  customerAgreementPartClipboard: [],
  standardAgreementPartClipboard: [],
  agreementPartClipboard: [],
  agreementServiceClipboard: [],
  agreementPeriodClipboard: [],
  agreementPriceClipboard: [],
  royaltyAgreementServiceClipboard: [],
  royaltyAgreementPeriodClipboard: [],
  royaltyAgreementPriceClipboard: [],
};

export const clipboardSlice = createSlice({
  name: 'clipboard',
  initialState,
  reducers: {
    setClipboard: (state, action) => {
      state = action.payload;
    },

    setAgreementClipboard: (state, action) => {
      if (action.payload) {
        state.agreementClipboard.push({ ...action.payload });
      }
    },

    setAgreementPartClipboard: (state, action) => {
      if (action.payload) {
        state.agreementPartClipboard.push({ ...action.payload });
      }
    },

    setAgreementServiceClipboard: (state, action) => {
      if (action.payload) {
        state.agreementServiceClipboard.push({ ...action.payload });
      }
    },

    setAgreementPeriodClipboard: (state, action) => {
      if (action.payload) {
        state.agreementPeriodClipboard.push({ ...action.payload });
      }
    },

    setAgreementPriceClipboard: (state, action) => {
      if (action.payload) {
        state.agreementPriceClipboard.push({ ...action.payload });
      }
    },
    setRoyaltyAgreementServiceClipboard: (state, action) => {
      if (action.payload) {
        state.royaltyAgreementServiceClipboard.push({ ...action.payload });
      }
    },
    setRoyaltyAgreementPeriodClipboard: (state, action) => {
      if (action.payload) {
        state.royaltyAgreementPeriodClipboard.push({ ...action.payload });
      }
    },
    setRoyaltyAgreementPriceClipboard: (state, action) => {
      if (action.payload) {
        state.royaltyAgreementPriceClipboard.push({ ...action.payload });
      }
    },
  },
});

export const {
  setClipboard,
  setAgreementPartClipboard,
  setAgreementServiceClipboard,
  setAgreementPeriodClipboard,
  setAgreementPriceClipboard,
  setRoyaltyAgreementServiceClipboard,
  setRoyaltyAgreementPeriodClipboard,
  setRoyaltyAgreementPriceClipboard,
} = clipboardSlice.actions;

export default clipboardSlice.reducer;
