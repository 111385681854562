import { createSlice } from '@reduxjs/toolkit';
import secureLocalStorage from "react-secure-storage";
const UserInfo = JSON.parse(secureLocalStorage.getItem('user'))

const initialState = {
    QMWeightDefinitions:{
        Data:[],
        CalculationData:{},
        Form:{
            airport: {
                select: [],
            },
            id:null,
            value:null,
            minFlightCount:null,
            maxFlightCount:null,
            percentage:null,
        },
        toggleEditKey:null,
        toggleDuplicateKey: null,
        tableOrder:{},
        searchParams:{}        

    },
    Permissions: {},
    ServiceType: [{id:1, label:'MAIN'},{id:2, label:'EXTRA'}],
    DomInt: [{id:1, label:'DOM'},{id:2, label:'INT'}],
    HandlingType: [{id:1, label:'Ramp Handling'}, {id:2,label:'Full Handling'},{id:3,label:'Cargo Handling'},{id:4, label:'Extra Handling'}],
    LoadType:[{id:1, label:'Pessenger'}, {id:2, label:'Cargo'}, {id:3, label:'P2C'}],
    FlightFactorData:[{id:1, label:'Technical'},{id:2, label:'Ferry'}]
}
export const QMWeightDefinitionsSlice = createSlice({
    name: 'QMWeightDefinitions',
    initialState,
    reducers:{
        setResultset: (state,action) => {
            state.QMWeightDefinitions.Data = action.payload
        },
        setPermissions: (state, action) => {
            state.Permissions = action.payload
        },
        setStep:(state,action)=>{
            state.QMWeightDefinitions.step=action.payload
        },
        setOrder:(state,action)=>{
            state.QMWeightDefinitions.tableOrder=action.payload
        },
        setToggleEditKey: (state, action)=>{
            state.QMWeightDefinitions.toggleEditKey=action.payload
        },
        setToggleDuplicateKey: (state, action)=>{
            state.QMWeightDefinitions.toggleDuplicateKey=action.payload
        }
    }
});

export const {
    setResultset,
    setPermissions,
    setOrder,
    setStep,
    setToggleEditKey,
    setToggleDuplicateKey,
} = QMWeightDefinitionsSlice.actions;
export default QMWeightDefinitionsSlice.reducer;