import request from './request';

import * as paths from './paths';

class roleGroup {
  /**
   *
   * list
   *
   * @static
   * @memberof roleGroup
   */
  static list = (params) => {
    return request.get(paths.roleGroupList, params);
  };
  /**
   *
   * roleGroup by id
   *
   * @static
   * @memberof roleGroup
   */
  static roleGroupById = (id) => {
    return request.get(paths.roleGroupById + id);
  };
  /**
   *
   * delete roleGroup
   *
   * @static
   * @memberof roleGroup
   */
  static delete = (id) => {
    return request.delete(paths.deleteRoleGroup + id);
  };
  /**
   *
   * save roleGroup
   *
   * @static
   * @memberof roleGroup
   */
  static save = (params) => {
    return request.post(paths.saveRoleGroup, params);
  };
  /**
   *
   * add role for roleGroup
   *
   * @static
   * @memberof roleGroup
   */
  static addRole = (params) => {
    return request.post(paths.addRoleGroup, params);
  };
  /**
   *
   * remove role for roleGroup
   *
   * @static
   * @memberof roleGroup
   */
  static removeRole = (params) => {
    return request.post(paths.removeRoleGroup, params);
  };
  /**
   *
   * role authority tree
   *
   * @static
   * @memberof roleGroup
   */
  static getAuthorityTree = (roleId) => {
    return request.get(paths.authorityTree + roleId);
  };
  /**
   *
   * save role authority tree
   *
   * @static
   * @memberof roleGroup
   */
  static saveAuthorityTree = (params, roleId) => {
    return request.post(paths.saveAuthorityTree + roleId, params);
  };
}

export { roleGroup };
