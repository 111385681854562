import { createSlice } from '@reduxjs/toolkit';
import secureLocalStorage from "react-secure-storage";
const UserInfo = JSON.parse(secureLocalStorage.getItem('user'))

const initialState = {
  accordionView: 'on',
  InvoiceSearch: true,
  Invoice: false,
  InvoiceStatus: false,
  Step: '1',
  FormData: {},
  InvoiceSearchForm: {
    Company: {
      data: [],
      select: null
    },
    InvoiceNo: "",
    Status: {
      data: [],
      select: []
    },
    FormBegin: new Date().toISOString(),
    FormEnd: new Date(new Date().setDate(new Date().getDate() + 7)).toISOString(),
    Customers: {
      data: [],
      select: []
    },
    Representative: {
      data: [],
      select: []
    },
    Airport: {
      data: [],
      select: []
    },
    Currency: {
      data: [],
      select: []
    },
  },
  Permissions: {},
  UpdateSearchResults: {}
};

export const InvoicesNavigationSlice = createSlice({
  name: 'InvoicesNavigation',
  initialState,
  reducers: {
    setStep: (state, action) => {
      state.Step = action.payload;
    },
    setFormData: (state, action) => {
      state.FormData = action.payload;
    },
    setAccordionViewOn: (state) => {
      state.accordionView = 'on';
    },
    setAccordionViewOff: (state) => {
      state.accordionView = 'off';
    },
    setStageName: (state, action) => {
      state.InvoiceSearch = true;
      state.Invoice = false;
      state.InvoiceStatus = false;
    },
    switchAccordionExpandedState: (state, action) => {
      switch (action.payload?.index) {
        case 0:
          state.InvoiceSearch = action.payload?.value;
          break;
        case 1:
          state.Invoice = action.payload?.value;
          break;
        case 2:
          state.InvoiceStatus = action.payload?.value;
          break;
        default:
      }
    },
    setInvoiceSearchForm: (state, action) => {
      state.InvoiceSearchForm = action.payload;
    },
    setCompany: (state, action) => {
      state.InvoiceSearchForm.Company.data = action.payload;
    },
    setCompanySelect: (state, action) => {
      state.InvoiceSearchForm.Company.select = action.payload;
    },
    setInvoiceNo: (state, action) => {
      state.InvoiceSearchForm.InvoiceNo = action.payload;
    },
    setStatus: (state, action) => {
      state.InvoiceSearchForm.Status = action.payload;
    },
    setFormBegin: (state, action) => {
      state.InvoiceSearchForm.FormBegin = action.payload;
    },
    setFormEnd: (state, action) => {
      state.InvoiceSearchForm.FormEnd = action.payload;
    },
    setCustomer: (state, action) => {
      state.InvoiceSearchForm.Customers.data = action.payload;
    },
    setCustomerSelect: (state, action) => {
      state.InvoiceSearchForm.Customers.select = action.payload;
    },
    setAirport: (state, action) => {
      state.InvoiceSearchForm.Airport.select = action.payload;
    },
    setAirportSelect: (state, action) => {
      state.InvoiceSearchForm.Airport.select = action.payload;
    },
    setRepresentative: (state, action) => {
      state.InvoiceSearchForm.Representative.select = action.payload;
    },
    setRepresentativeSelect: (state, action) => {
      state.InvoiceSearchForm.Representative.select = action.payload;
    },
    setCurrency: (state, action) => {
      state.InvoiceSearchForm.Currency.data = action.payload;
    },
    setCurrencySelect: (state, action) => {
      state.InvoiceSearchForm.Currency.select = action.payload;
    },
    setPermissions: (state, action) => {
      state.Permissions = action.payload
    },
    setStatusSelect: (state, action) => {
      state.InvoiceSearchForm.Status.select = action.payload;
    },
    setInvoiceFinalize: (state, action) => {
    
      state.FormData.SapDocumentNo = action.payload.sapDocumentNo
      state.FormData.SapReversalDocumentNo = action.payload.sapReversalDocumentNo
      state.FormData.Einvoiceuuid = action.payload.einvoiceuuid
      state.FormData.Einvoicestatus = action.payload.einvoicestatus
      state.FormData.Status = action.payload.status
   {/*
      state.FormData.Displaying.SapDocumentNo = action.payload.sapDocumentNo
      state.FormData.Displaying.SapReversalDocumentNo = action.payload.sapReversalDocumentNo
      state.FormData.Displaying.Einvoiceuuid = action.payload.einvoiceuuid
      state.FormData.Displaying.Einvoicestatus = action.payload.einvoicestatus
      state.FormData.Displaying.Status = action.payload.status
       */}
    },
    setUpdateSearchResults: (state, action)=>{
      state.UpdateSearchResults = action.payload
    },
    resetInvoiceSearchForm: (state,action)=>{
      state.InvoiceSearchForm = initialState.InvoiceSearchForm
    }
  },
});

export const {
  setAccordionViewOn,
  setAccordionViewOff,
  setStageName,
  switchAccordionExpandedState,
  setStep,
  setFormData,
  setCompany,
  setCompanySelect,
  setInvoiceNo,
  setStatus,
  setCustomer,
  setCustomerSelect,
  setFormBegin,
  setFormEnd,
  setAirport,
  setAirportSelect,
  setRepresentative,
  setRepresentativeSelect,
  setCurrency,
  setCurrencySelect,
  setPermissions,
  setStatusSelect,
  setInvoiceFinalize,
  setUpdateSearchResults,
  resetInvoiceSearchForm
} = InvoicesNavigationSlice.actions;
export default InvoicesNavigationSlice.reducer;