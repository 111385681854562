import { createSlice, current } from '@reduxjs/toolkit';
import { FormStates, SaveStates } from '../../../constants/formStates';
import _ from 'lodash';

const initialState = {
  updateRecord: null,
  lastSavedRecord: null,
  saveResponseAgreement: null,
  formState: FormStates.unChanged,
  globalLoading: false,
  agreementList: null,
  selectedAgreement: null,
  selectedAgreementLoading: false,
  selectedAgreementId: null,
  selectedAgreementServicesLoading: false,
  selectedAgreementServicesCount: 0,
  selectedAgreementServiceIndex: -1,
  selectedAgreementServicesDetailsLoading: false,
  selectedAgreementPeriodIndex: -1,
  selectedAgreementPeriodsCount: 0,
  selectedAgreementPriceIndex: -1,
  selectedAgreementPricesCount: 0,
  newAgreement: false,
  newAgreementService: false,
  newAgreementServiceDetails: null,
  editedAgreementService: null,
  updateForAgreementService: null,
  newAgreementPeriod: false,
  newAgreementPeriodDetails: null,
  editedAgreementPeriod: null,
  updateForAgreementPeriod: null,
  periodListForDelete: [],
  newAgreementPrice: false,
  newAgreementPriceDetails: null,
};

function arrangeUpdateRecord(state, position, record, service = null, period = null, price = null) {
  // Agreement Main
  if (!state.updateRecord) state.updateRecord = { id: state.selectedAgreement.id };
  if (position === 'agreement') {
    state.updateRecord = { ...state.updateRecord, ...record };
    return;
  }

  //Agreement Service
  if (!state.updateRecord.AgreementServices) {
    state.updateRecord = {
      ...state.updateRecord,
      ...{ AgreementServices: [] },
    };
  }
  if (position === 'new_service') {
    state.updateRecord.AgreementServices.push(record);
    return;
  }
  let serviceIndex = state.updateRecord.AgreementServices.findIndex((x) => x.id === service.id);
  if (serviceIndex < 0) {
    state.updateRecord.AgreementServices.push({ id: service.id });
    serviceIndex = state.updateRecord.AgreementServices.findIndex((x) => x.id === service.id);
    console.assert(serviceIndex >= 0, 'Service not found');
  }
  if (position === 'service_update') {
    state.updateRecord.AgreementServices[serviceIndex] = {
      ...state.updateRecord.AgreementServices[serviceIndex],
      ...record,
    };
    return;
  }

  //Agreement Service Period
  if (!state.updateRecord.AgreementServices[serviceIndex].royaltyAgreementPeriod) {
    state.updateRecord.AgreementServices[serviceIndex] = {
      ...state.updateRecord.AgreementServices[serviceIndex],
      ...{ royaltyAgreementPeriod: [] },
    };
  }
  if (position === 'new_period') {
    state.updateRecord.AgreementServices[serviceIndex].royaltyAgreementPeriod.push(record);
    console.log(current(state));
    return;
  }
  let periodIndex = state.updateRecord.AgreementServices[
    serviceIndex
  ].royaltyAgreementPeriod.findIndex((x) => x.id === period.id);
  if (periodIndex < 0) {
    state.updateRecord.AgreementServices[serviceIndex].royaltyAgreementPeriod.push({
      id: period.id,
    });
    periodIndex = state.updateRecord.AgreementServices[
      serviceIndex
    ].royaltyAgreementPeriod.findIndex((x) => x.id === period.id);
    console.assert(periodIndex >= 0, 'Period not found');
  }
  if (position === 'period_update') {
    state.updateRecord.AgreementServices[serviceIndex].royaltyAgreementPeriod[periodIndex] = {
      ...state.updateRecord.AgreementServices[serviceIndex].royaltyAgreementPeriod[periodIndex],
      ...record,
    };
    return;
  }

  // Agreement Service Price
  if (
    !state.updateRecord.AgreementServices[serviceIndex].royaltyAgreementPeriod[periodIndex]
      .pricingConditions
  ) {
    state.updateRecord.AgreementServices[serviceIndex].royaltyAgreementPeriod[periodIndex] = {
      ...state.updateRecord.AgreementServices[serviceIndex].royaltyAgreementPeriod[periodIndex],
      ...{ pricingConditions: [] },
    };
  }
  if (position === 'new_price') {
    state.updateRecord.AgreementServices[serviceIndex].royaltyAgreementPeriod[
      periodIndex
    ].pricingConditions.push(record);
    return;
  }
  let priceIndex = state.updateRecord.AgreementServices[serviceIndex].royaltyAgreementPeriod[
    periodIndex
  ].pricingConditions.findIndex((x) => x.id === price.id);
  if (priceIndex < 0) {
    state.updateRecord.AgreementServices[serviceIndex].royaltyAgreementPeriod[
      periodIndex
    ].pricingConditions.push({ id: price.id });
    priceIndex = state.updateRecord.AgreementServices[serviceIndex].royaltyAgreementPeriod[
      periodIndex
    ].pricingConditions.findIndex((x) => x.id === price.id);
    console.assert(priceIndex >= 0, 'Price not found');
  }
  if (position === 'price_update' && priceIndex >= 0) {
    state.updateRecord.AgreementServices[serviceIndex].royaltyAgreementPeriod[
      periodIndex
    ].pricingConditions[priceIndex] = {
      ...state.updateRecord.AgreementServices[serviceIndex].royaltyAgreementPeriod[periodIndex]
        .pricingConditions[priceIndex],
      ...record,
    };
    return;
  }
}

export const selectedRASlice = createSlice({
  name: 'RAselectedAgreement',
  initialState,
  reducers: {
    createNewAgreement: (state, action) => {
      const { uniqueId, agreement } = action.payload || {
        uniqueId: null,
        agreement: null,
      };
      if (state.newAgreement === false) {
        //Create a new record just user not start add operation before otherwise continue the same add op.
        if (agreement) {
          //copy
          let newAgreement = {
            ...agreement,
            id: 'new_' + uniqueId,
            description: "Coppied One, Don't forget to save after you finish editing",
          };
          giveIdsAgreementServices({
            agreement: newAgreement,
            uniqueId: uniqueId,
          });
          state.selectedAgreement = newAgreement;
          state.selectedAgreementId = newAgreement.id;
          arrangeUpdateRecord(state, 'agreement', newAgreement);
        } else {
          //add
          state.selectedAgreement = {
            id: 'new_' + uniqueId,
            AgreementServices: [],
          };
          state.selectedAgreementId = 'new_' + uniqueId;
          state.newAgreementServiceDetails = null;
          state.selectedAgreementServicesCount = null;
          state.selectedAgreementPeriod = null;
          state.selectedAgreementPrice = null;
          state.selectedAgreementPricesCount = null;
          state.updateRecord = {}; // no id on insertion
        }
        state.agreementList = null;
        state.formState = FormStates.modified;
        state.newAgreement = true;
      }
    },

    setAgreementList: (state, action) => {
      state.agreementList = action.payload;
    },

    setSelectedAgreement: (state, action) => {
      state.selectedAgreement = action.payload;
      state.selectedAgreementId = action.payload?.id;
      state.updateRecord = null;
      state.formState = FormStates.unChanged;
      state.saveState = SaveStates.idle;
      state.saveResponseAgreement = null;
      state.newAgreement = false;
      state.periodListForDelete = null;
    },

    deleteAgreement: (state, action) => {
      const agreementIdToRemove = action.payload;
      const agreementToRemove = state.agreementList?.find((e) => e.id === agreementIdToRemove);
      if (agreementToRemove) {
        state.agreementList = state.agreementList.filter((e) => e.id !== agreementIdToRemove);
      }
    },

    updateSelectedAgreement: (state, action) => {
      state.selectedAgreement = {
        ...state.selectedAgreement,
        ...action.payload,
      };
      arrangeUpdateRecord(state, 'agreement', action.payload);
      state.formState = FormStates.modified;
    },

    setSelectedAgreementServices: (state, action) => {
      const services = action.payload || {};
      if (!state.selectedAgreement?.AgreementServices) {
        state.selectedAgreement = {
          ...state.selectedAgreement,
          ...{ AgreementServices: [...services] },
        };
      } else {
        state.selectedAgreement.AgreementServices = [...services];
      }
    },

    createNewAgreementService: (state, action) => {
      const { uniqueId, service } = action.payload || {
        uniqueId: null,
        service: null,
      };
      const uniqueIdText = 'new_service_' + uniqueId;
      if (service) {
        let newService = { ...service, id: uniqueIdText };
        giveIdsServicePeriods({ service: newService, uniqueId: uniqueId });
        state.newAgreementServiceDetails = newService;
      } else {
        state.newAgreementServiceDetails = { id: uniqueIdText };
      }
      // state.selectedAgreementServiceIndex = -1;
      state.newAgreementService = true;
    },

    saveNewAgreementService: (state) => {
      arrangeUpdateRecord(state, 'new_service', state.newAgreementServiceDetails);
      if (!state.selectedAgreement.AgreementServices)
        state.selectedAgreement.AgreementServices = [];

      let services = [...state.selectedAgreement.AgreementServices];
      services.push({ ...state.newAgreementServiceDetails });
      services = _.sortBy(services, ['serviceRoyaltyDto.name', 'packageDefinition']);

      state.selectedAgreement.AgreementServices = [...services];

      state.formState = FormStates.modified;
    },

    deleteAgreementService: (state, action) => {
      const serviceIdToRemove = action.payload;
      const serviceToRemove = state.selectedAgreement.AgreementServices?.find(
        (e) => e.id === serviceIdToRemove
      );
      if (serviceToRemove) {
        state.selectedAgreement.AgreementServices =
          state.selectedAgreement.AgreementServices.filter((e) => e.id !== serviceIdToRemove);
      }
    },

    updateSelectedAgreementServiceDetails: (state, action) => {
      if (state.newAgreementService) {
        state.newAgreementServiceDetails = {
          ...state.newAgreementServiceDetails,
          ...action.payload,
        };
      } else {
        const service =
          state.selectedAgreement.AgreementServices[state.selectedAgreementServiceIndex];
        arrangeUpdateRecord(state, 'service_update', action.payload, service);

        state.selectedAgreement.AgreementServices[state.selectedAgreementServiceIndex] = {
          ...state.selectedAgreement.AgreementServices[state.selectedAgreementServiceIndex],
          ...action.payload,
        };

        state.formState = FormStates.modified;
      }
    },

    setSelectedAgreementServiceIndex: (state, action) => {
      const serviceIndex = action.payload ?? -1;
      const service = state.selectedAgreement.AgreementServices[serviceIndex] ?? null;
      state.selectedAgreementServiceIndex = serviceIndex;

      state.selectedAgreementPeriodsCount = service?.royaltyAgreementPeriod?.length ?? 0;
      state.editedAgreementService = service;
      state.updateForAgreementService = {};
      state.newAgreementService = false;
      state.periodListForDelete = null;
    },

    updateEditedService: (state, action) => {
      if (state.newAgreementService) {
        state.newAgreementServiceDetails = {
          ...state.newAgreementServiceDetails,
          ...action.payload,
        };
      } else {
        state.editedAgreementService = {
          ...state.editedAgreementService,
          ...action.payload,
        };
        state.updateForAgreementService = {
          ...state.updateForAgreementService,
          ...action.payload,
        };
      }
    },

    createNewAgreementPeriod: (state, action = null) => {
      const { uniqueId, period } = action.payload || {
        uniqueId: null,
        period: null,
      };
      const uniqueIdText = 'new_period_' + uniqueId;
      if (period) {
        let newPeriod = { ...period, id: uniqueIdText };
        giveIdsPeriodPrices({ period: newPeriod, uniqueId: uniqueId });
        state.newAgreementPeriodDetails = { ...newPeriod, id: uniqueIdText };
      } else state.newAgreementPeriodDetails = { id: uniqueIdText };
      state.newAgreementPeriod = true;
      state.selectedAgreementPeriodIndex = -1;
    },

    saveNewAgreementPeriod: (state) => {
      const service =
        state.selectedAgreement.AgreementServices[state.selectedAgreementServiceIndex];
      arrangeUpdateRecord(state, 'new_period', state.newAgreementPeriodDetails, service);
      if (
        !state.selectedAgreement.AgreementServices[state.selectedAgreementServiceIndex]
          .royaltyAgreementPeriod
      )
        state.selectedAgreement.AgreementServices[
          state.selectedAgreementServiceIndex
        ].royaltyAgreementPeriod = [];

      state.selectedAgreement.AgreementServices[
        state.selectedAgreementServiceIndex
      ].royaltyAgreementPeriod.push(state.newAgreementPeriodDetails);

      state.formState = FormStates.modified;
    },

    deleteAgreementPeriod: (state, action) => {
      const periodIdToRemove = action.payload;
      const periodToRemove = state.selectedAgreement.AgreementServices[
        state.selectedAgreementServiceIndex
      ].royaltyAgreementPeriod 

      if (periodToRemove) {
        state.selectedAgreement.AgreementServices[
          state.selectedAgreementServiceIndex
        ].royaltyAgreementPeriod = state.selectedAgreement.AgreementServices[
          state.selectedAgreementServiceIndex
        ].royaltyAgreementPeriod.filter((e) => !periodIdToRemove.includes(e.id));
      }
    },

    setPeriodListForDelete : (state, action) => {
      state.periodListForDelete = action.payload;
    },

    setSelectedAgreementPeriodIndex: (state, action) => {
      state.selectedAgreementPeriodIndex = action.payload ?? -1;
      state.newAgreementPeriod = false;
      if (
        state.selectedAgreementServiceIndex >= 0 &&
        state.selectedAgreementServiceIndex < state.selectedAgreement?.AgreementServices.length &&
        state.selectedAgreementPeriodIndex >= 0 &&
        state.selectedAgreementPeriodIndex <
          state.selectedAgreement?.AgreementServices[state.selectedAgreementServiceIndex]
            .royaltyAgreementPeriod.length
      ) {
        state.editedAgreementPeriod =
          state.selectedAgreement?.AgreementServices[
            state.selectedAgreementServiceIndex
          ]?.royaltyAgreementPeriod[state.selectedAgreementPeriodIndex];
      }
      state.newAgreementPeriod = false;
    },

    updateEditedPeriod: (state, action) => {
      if (state.newAgreementPeriod) {
        state.newAgreementPeriodDetails = {
          ...state.newAgreementPeriodDetails,
          ...action.payload,
        };
      } else {
        state.editedAgreementPeriod = {
          ...state.editedAgreementPeriod,
          ...action.payload,
        };
        state.updateForAgreementPeriod = {
          ...state.updateForAgreementPeriod,
          ...action.payload,
        };
      }
    },

    updateSelectedAgreementPeriod: (state, action) => {
      if (state.newAgreementPeriod) {
        state.newAgreementPeriodDetails = {
          ...state.newAgreementPeriodDetails,
          ...action.payload,
        };
      } else {
        const service =
          state.selectedAgreement.AgreementServices[state.selectedAgreementServiceIndex];

        const period =
          state.selectedAgreement.AgreementServices[state.selectedAgreementServiceIndex]
            .royaltyAgreementPeriod[state.selectedAgreementPeriodIndex];
        arrangeUpdateRecord(state, 'period_update', action.payload, service, period);
        state.selectedAgreement.AgreementServices[
          state.selectedAgreementServiceIndex
        ].royaltyAgreementPeriod[state.selectedAgreementPeriodIndex] = {
          ...state.selectedAgreement.AgreementServices[state.selectedAgreementServiceIndex]
            .royaltyAgreementPeriod[state.selectedAgreementPeriodIndex],
          ...action.payload,
        };

        state.formState = FormStates.modified;
      }
    },

    createNewAgreementPrice: (state, action) => {
      const { uniqueId, price } = action.payload || {
        uniqueId: null,
        price: null,
      };
      const uniqueIdText = 'new_price_' + uniqueId;
      if (price) {
        state.newAgreementPriceDetails = { ...price, id: uniqueIdText };
      } else {
        state.newAgreementPriceDetails = {
          ...price,
          id: uniqueIdText,
          parentTag: price?.parentTag,
          tag: price?.tag,
        };
      }
      state.newAgreementPrice = true;
      state.selectedAgreementPriceIndex = null;
      state.selectedAgreementPricesCount = 0;
    },

    saveNewAgreementPrice: (state, action) => {
      const { newPrice, serviceIndex, periodIndex } = action.payload || {};
      const service = state.selectedAgreement.AgreementServices[serviceIndex];
      const period =
        state.selectedAgreement.AgreementServices[serviceIndex].royaltyAgreementPeriod[periodIndex];
      arrangeUpdateRecord(state, 'new_price', newPrice, service, period);
      if (
        !state.selectedAgreement.AgreementServices[serviceIndex].royaltyAgreementPeriod[periodIndex]
          .pricingConditions
      ) {
        state.selectedAgreement.AgreementServices[serviceIndex].royaltyAgreementPeriod[
          periodIndex
        ].pricingConditions = [];
      }
      state.selectedAgreement.AgreementServices[serviceIndex].royaltyAgreementPeriod[
        periodIndex
      ].pricingConditions.push({ ...newPrice });
      state.formState = FormStates.modified;
    },

    //prettier-ignore
    deleteAgreementPrice: (state, action) => {
      const priceIdToRemove = action.payload;
      const priceToRemove = state.selectedAgreement
                                 .AgreementServices[state.selectedAgreementServiceIndex]
                                 .royaltyAgreementPeriod[state.selectedAgreementPeriodIndex]
                                 .pricingConditions;

      if (priceToRemove) {
        state.selectedAgreement.AgreementServices[state.selectedAgreementServiceIndex]
             .royaltyAgreementPeriod[state.selectedAgreementPeriodIndex].pricingConditions =
          state.selectedAgreement.AgreementServices[state.selectedAgreementServiceIndex]
             .royaltyAgreementPeriod[state.selectedAgreementPeriodIndex]
             .pricingConditions.filter((e) =>!priceIdToRemove.includes(e.id));
      }
    },

    setSelectedAgreementPriceIndex: (state, action) => {
      state.selectedAgreementPriceIndex = _.findIndex(
        state.selectedAgreement.AgreementServices[state.selectedAgreementServiceIndex]
          .royaltyAgreementPeriod[state.selectedAgreementPeriodIndex].pricingConditions,
        { id: action.payload?.id }
      );
      state.newAgreementPrice = false;
    },

    updateAgreementPriceById: (state, action) => {
      const { priceId, record } = action.payload || {};
      const priceIndex = state.selectedAgreement.AgreementServices[
        state.selectedAgreementServiceIndex
      ].royaltyAgreementPeriod[state.selectedAgreementPeriodIndex].pricingConditions.findIndex(
        (e) => e.id === priceId
      );
      if (priceIndex >= 0) {
        state.selectedAgreement.AgreementServices[
          state.selectedAgreementServiceIndex
        ].royaltyAgreementPeriod[state.selectedAgreementPeriodIndex].pricingConditions[priceIndex] =
          {
            ...state.selectedAgreement.AgreementServices[state.selectedAgreementServiceIndex]
              .royaltyAgreementPeriod[state.selectedAgreementPeriodIndex].pricingConditions[
              priceIndex
            ],
            ...record,
          };

        const service =
          state.selectedAgreement.AgreementServices[state.selectedAgreementServiceIndex];
        const period =
          state.selectedAgreement.AgreementServices[state.selectedAgreementServiceIndex]
            .royaltyAgreementPeriod[state.selectedAgreementPeriodIndex];
        const price =
          state.selectedAgreement.AgreementServices[state.selectedAgreementServiceIndex]
            .royaltyAgreementPeriod[state.selectedAgreementPeriodIndex].pricingConditions[
            priceIndex
          ];
        arrangeUpdateRecord(state, 'price_update', record, service, period, price);
        state.formState = FormStates.modified;
      }
    },

    updateSelectedAgreementPrice: (state, action) => {
      if (state.newAgreementPrice) {
        state.newAgreementPriceDetails = {
          ...state.newAgreementPriceDetails,
          ...action.payload,
        };
      } else {
        const service =
          state.selectedAgreement.AgreementServices[state.selectedAgreementServiceIndex];
        const period =
          state.selectedAgreement.AgreementServices[state.selectedAgreementServiceIndex]
            .royaltyAgreementPeriod[state.selectedAgreementPeriodIndex];
        const price =
          state.selectedAgreement.AgreementServices[state.selectedAgreementServiceIndex]
            .royaltyAgreementPeriod[state.selectedAgreementPeriodIndex].pricingConditions[
            state.selectedAgreementPriceIndex
          ];
        arrangeUpdateRecord(state, 'price_update', action.payload, service, period, price);

        state.selectedAgreement.AgreementServices[
          state.selectedAgreementServiceIndex
        ].royaltyAgreementPeriod[state.selectedAgreementPeriodIndex].pricingConditions[
          state.selectedAgreementPriceIndex
        ] = {
          ...state.selectedAgreement.AgreementServices[state.selectedAgreementServiceIndex]
            .royaltyAgreementPeriod[state.selectedAgreementPeriodIndex].pricingConditions[
            state.selectedAgreementPriceIndex
          ],
          ...action.payload,
        };
        state.formState = FormStates.modified;
      }
    },

    setLastSavedRecord: (state, action) => {
      state.lastSavedRecord = action.payload;
    },
    setSaveResponseAgreement: (state, action) => {
      state.saveResponseAgreement = action.payload;
    },
    setFormState: (state, action) => {
      state.formState = action.payload;
    },
    setSaveState: (state, action) => {
      state.saveState = action.payload;
    },
    setGlobalLoading: (state, action) => {
      state.globalLoading = action.payload;
    },
    setSelectedAgreementLoading: (state, action) => {
      state.selectedAgreementLoading = action.payload;
    },
    setSelectedAgreementServicesLoading: (state, action) => {
      state.selectedAgreementServicesLoading = action.payload;
    },
    setSelectedAgreementServiceDetailsLoading: (state, action) => {
      state.selectedAgreementServiceDetailsLoading = action.payload;
    },

    setClearRoyaltyAgreementSlice: (state, action) => {
      return initialState;
    },
    setClearUpdateRecord: (state, action) => {
      state.updateRecord = null;
    },

    ClearSelections: (state, action) => {
      const level = action?.payload ?? null;
      if (level === 'agreement') {
        state.selectedAgreement = null;
        state.selectedAgreementId = null;
      }
      if (level === 'service' || level === 'agreement') {
        state.selectedAgreementServiceIndex = -1;
        state.selectedAgreementPeriodsCount = 0;
      }
      if (level === 'period' || level === 'service' || level === 'agreement') {
        state.selectedAgreementPeriodIndex = -1;
        state.selectedAgreementPricesCount = 0;
      }
      if (level === 'price' || level === 'period' || level === 'service' || level === 'agreement') {
        state.selectedAgreementPriceIndex = -1;
      }
      if (level !== 'agreement' && level !== 'service' && level !== 'period' && level !== 'price') {
        throw new Error('Invalid Clear Selections Level');
      }
    },
  },
});

export const {
  createNewAgreement,
  setAgreementList,
  setSelectedAgreement,
  deleteAgreement,
  updateSelectedAgreement,

  setSelectedAgreementServices,
  createNewAgreementService,
  saveNewAgreementService,
  setSelectedAgreementServiceIndex,
  updateSelectedAgreementServiceDetails,
  updateEditedService,

  createNewAgreementPeriod,
  saveNewAgreementPeriod,
  setSelectedAgreementPeriodIndex,
  deleteAgreementPeriod,
  updateSelectedAgreementPeriod,
  updateEditedPeriod,
  setPeriodListForDelete,

  createNewAgreementPrice,
  saveNewAgreementPrice,
  deleteAgreementPrice,
  setSelectedAgreementPriceIndex,
  setSelectedAgreementPrice, // OLD Function keep it for now
  deleteAgreementService,
  updateSelectedAgreementPrice,
  updateAgreementPriceById,
  setLastSavedRecord,
  setSaveResponseAgreement,
  setFormState,
  setSaveState,
  setGlobalLoading,

  setSelectedAgreementLoading,
  setSelectedAgreementServicesLoading,
  setSelectedAgreementServiceDetailsLoading,

  setClearRoyaltyAgreementSlice,
  setClearUpdateRecord,
  ClearSelections,
} = selectedRASlice.actions;

export default selectedRASlice.reducer;

const giveIdsAgreementServices = ({ agreement, uniqueId }) => {
  let newServices = [];
  let nextUniqueId = uniqueId + 1;
  if (agreement?.AgreementServices) {
    for (let i = 0; i < agreement.AgreementServices.length; i++) {
      let service = { ...agreement.AgreementServices[i], id: 'new_service_' + nextUniqueId++ };
      giveIdsServicePeriods({ service, uniqueId: nextUniqueId });
      newServices.push(service);
    }
    agreement.AgreementServices = newServices;
  }
};

const giveIdsServicePeriods = ({ service, uniqueId }) => {
  let newPeriods = [];
  let nextUniqueId = uniqueId + 1;
  if (service?.royaltyAgreementPeriod) {
    for (let i = 0; i < service.royaltyAgreementPeriod.length; i++) {
      let period = { ...service.royaltyAgreementPeriod[i], id: 'new_period_' + nextUniqueId++ };
      giveIdsPeriodPrices({ period, uniqueId: nextUniqueId });
      newPeriods.push(period);
    }
    service.royaltyAgreementPeriod = newPeriods;
  }
};
const giveIdsPeriodPrices = ({ period, uniqueId }) => {
  let newPrices = [];
  let nextUniqueId = uniqueId + 1;
  if (period?.pricingConditions) {
    for (let i = 0; i < period.pricingConditions.length; i++) {
      newPrices.push({ ...period.pricingConditions[i], id: 'new_price_' + nextUniqueId++ });
    }
    period.pricingConditions = newPrices;
  }
};
