import request from './request';

import * as paths from './paths';

class auth {
  /**
   *
   * login
   *
   * @static
   * @memberof auth
   */
  static login = (params) => {
    return request.post(paths.login, params);
  };
  /**
   *
   * change password
   *
   * @static
   * @memberof auth
   */
  static changePassword = (params) => {
    return request.post(paths.changePassword, params);
  };
  /**
   *
   * forgot password
   *
   * @static
   * @memberof auth
   */
  static forgotPassword = (params) => {
    return request.post(paths.forgotPassword, params);
  };
  /**
   *
   * public reset password
   *
   * @static
   * @memberof auth
   */
  static publicResetPassword = (params) => {
    return request.post(paths.publicResetPassword, params);
  };
  /**
   *
   * reset password
   *
   * @static
   * @memberof auth
   */
  static resetPassword = (params) => {
    return request.post(paths.resetPassword, params);
  };
  /**
   *
   * userinfo
   *
   * @static
   * @memberof auth
   */
  static userInfo = (params) => {
    return request.get(paths.own, params);
  };
  /**
   *
   * logout
   *
   * @static
   * @memberof auth
   */
  static logout = (params) => {
    return request.post(paths.logout, params);
  };
  /**
   *
   * user activate
   *
   * @static
   * @memberof auth
   */
  static activate = (params) => {
    return request.post(paths.userActivate, params);
  };
  /**
   *
   * user save
   *
   * @static
   * @memberof auth
   */
  static save = (params) => {
    return request.post(paths.userSave, params);
  };
  /**
   *
   * user save selected language
   *
   * @static
   * @memberof auth
   */
  static saveSelectedLang = (params) => {
    return request.post(paths.selectedLanguage, params);
  };
  /**
   *
   * user save selected language
   *
   * @static
   * @memberof auth
   */
  static verifyRecaptcha = (params) => {
    return request.post(paths.recaptcha, params);
  };
  /**
   *
   * user add role group
   *
   * @static
   * @memberof auth
   */
  static addRoleGroup = (params) => {
    return request.post(paths.userAddRoleGroup, params);
  };
  /**
   *
   * user remove role group
   *
   * @static
   * @memberof auth
   */
  static removeRoleGroup = (params) => {
    return request.post(paths.userRemoveRoleGroup, params);
  };
  /**
   *
   * user list
   *
   * @static
   * @memberof auth
   */
  static userList = (params) => {
    return request.get(paths.userList, params);
  };
  /**
   *
   * user pageable list
   *
   * @static
   * @memberof auth
   */
  static userPageable = (filter = {}, pagination = {}) => {
    return request.post(paths.userPageable, {
      ...filter
    }, {
      page: pagination?.page,
      size: pagination?.size
    });
  };
  /**
   *
   * user list by id
   *
   * @static
   * @memberof auth
   */
  static getUserById = (id) => {
    return request.get(paths.userById + id);
  };
  /**
   *
   * user branch
   *
   * @static
   * @memberof auth
   */
  static branchList = (params) => {
    return request.get(paths.branchList, params);
  };
  /**
   *
   * user department
   *
   * @static
   * @memberof auth
   */
  static departmentList = async (branchId = null) => {
    return await request.get(`${paths.departmentList}${branchId !== null ? `/${branchId}` : ''}`);
  };
  /**
   *
   * user company list
   *
   * @static
   * @memberof auth
   */
  static companyList = (params) => {
    return request.get(paths.companyList, params);
  };
  /**
   *
   * user add company
   *
   * @static
   * @memberof auth
   */
  static addCompany = (params) => {
    return request.post(paths.addCompany, params);
  };
  /**
   *
   * user remove company
   *
   * @static
   * @memberof auth
   */
  static removeCompany = (params) => {
    return request.post(paths.removeCompany, params);
  };
  /**
   *
   * send activation again
   *
   * @static
   * @memberof auth
   */
  static sendActivationMail = (params) => {
    return request.post(paths.sendActivationMail, params);
  };
}

export { auth };
