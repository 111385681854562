import { useTheme } from '@emotion/react';
import { m } from 'framer-motion';
import Logo from '../Logo';
import { Backdrop, Box, Typography, Stack } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import {useSelector} from "react-redux";


const RootStyle = styled('div')(({ theme }) => ({
    right: 0,
    bottom: 100,
    zIndex: 99999,
    width: '100%',
    height: '100%',
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

function GlobalSpinner() {
    let open = false;
    const globalLoading = useSelector((state) =>{
        return state.MainUI.globalLoading
    });

    const theme = useTheme();

    return (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={globalLoading.open}>
            <Stack spacing={8} sx={{ alignItems: 'center' }}>
                <RootStyle>
                    <m.div
                        initial={{ rotateY: 0 }}
                        animate={{ rotateY: 360 }}
                        transition={{
                            duration: 2,
                            ease: 'easeInOut',
                            repeatDelay: 1,
                            repeat: Infinity,
                        }}
                    >
                        <Logo disabledLink sx={{ width: 167, height: 70 }} />
                    </m.div>

                    <Box
                        component={m.div}
                        animate={{
                            scale: [1.2, 1, 1, 1.2, 1.2],
                            rotate: [270, 0, 0, 270, 270],
                            opacity: [0.25, 1, 1, 1, 0.25],
                            borderRadius: ['25%', '25%', '50%', '50%', '25%'],
                        }}
                        transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
                        sx={{
                            width: 100,
                            height: 100,
                            borderRadius: '25%',
                            position: 'absolute',
                            border: (theme) => `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`,
                        }}
                    />

                    <Box
                        component={m.div}
                        animate={{
                            scale: [1, 1.2, 1.2, 1, 1],
                            rotate: [0, 270, 270, 0, 0],
                            opacity: [1, 0.25, 0.25, 0.25, 1],
                            borderRadius: ['25%', '25%', '50%', '50%', '25%'],
                        }}
                        transition={{
                            ease: 'linear',
                            duration: 3.2,
                            repeat: Infinity,
                        }}
                        sx={{
                            width: 120,
                            height: 120,
                            borderRadius: '25%',
                            position: 'absolute',
                            border: (theme) => `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,
                        }}
                    />
                </RootStyle>
                <Box>
                    <Typography variant='h4' color={theme.palette.background.datagridSelections}>
                        {globalLoading.info}
                    </Typography>
                </Box>
            </Stack>
        </Backdrop>
    );
}

export default GlobalSpinner;
